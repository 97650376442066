import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { TabView, TabPanel } from "primereact/tabview";
import { useParams, useHistory, useLocation } from "react-router-dom";

import { confirmDialog } from "primereact/confirmdialog";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";

import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import FloatingSave from "../../../components/FloatingSave";
import SindicanciasProfano, { IrmaoDTO, SindicanciaProfano } from "../../../components/SindicanciasProfano";
import InformacoesProfano from "../../../components/InformacoesProfano";
import {
  SituacaoProfano,
  SituacaoProfanoElement,
  situacoesProfanoMap,
} from "../../../components/ProfanoSituacaoComponent";
import { profanoErrors } from "../../../errors/profano";
import AnexosProfano, { AnexoProfano } from "../../../components/AnexosProfano";

interface Params {
  id: string;
}

interface PageParams {
  nome: string;
}

export interface TurmaProfano {
  uuid: string;
  nome?: string;
  dtIniciacao: string;
}

export interface ProfanoInfo {
  uuid: string;
  nome: string;
  foto: string;
  turma: TurmaProfano;
  situacao: SituacaoProfano;
}

export interface ProfanoForm {
  // obrigatórios
  informacoes: ProfanoInfo;
  anexos: AnexoProfano[];
  // escrutinio: string;
  sindicancias: SindicanciaProfano[];
}

const ProfanoFormPage: React.FC = () => {
  const [sindicantesOptions, setSindicantesOptions] = useState<IrmaoDTO[]>([]);
  const [loadingSindicantes, setLoadingSindicantes] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  // const [isDirty, setIsDirty] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [profanoInicial, setProfanoInicial] = useState<ProfanoForm>({} as ProfanoForm);

  const [nome, setNome] = useState<string>();
  const [informacoes, setInformacoes] = useState({} as ProfanoInfo);
  const [sindicancias, setSindicancias] = useState<SindicanciaProfano[]>([]);
  const [anexos, setAnexos] = useState<AnexoProfano[]>([]);

  const situacaoProfanoOptions = useMemo(
    (): SituacaoProfanoElement[] => [
      situacoesProfanoMap.AGUARDANDO,
      situacoesProfanoMap.PRONTO_PARA_LEITURA,
      situacoesProfanoMap.LIDO,
      situacoesProfanoMap.SINDICANCIA,
      situacoesProfanoMap.ESCRUTINIO,
      situacoesProfanoMap.APROVADO,
      situacoesProfanoMap.PAGO,
      situacoesProfanoMap.INICIADO,
      situacoesProfanoMap.REJEITADO,
      situacoesProfanoMap.DESISTENCIA,
    ],
    []
  );

  const [turmaProfanoOptions, setTurmaProfanoOptions] = useState<TurmaProfano[]>([]);

  const { showToast } = useToast();
  const { handleError } = useError();

  const { id } = useParams<Params>();
  const location = useLocation<PageParams>();
  const history = useHistory();

  const loadSindicantes = useCallback(() => {
    setLoadingSindicantes(true);
    api
      // .get<IrmaoDTO[]>(`profanos`)
      .get<IrmaoDTO[]>(`dropdown/sindicantes`)
      .then(({ data: sindicantes }) => {
        setSindicantesOptions(sindicantes);
      })
      .catch((error) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as opções de sindicantes",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => setLoadingSindicantes(false));
  }, [showToast]);

  const loadTurmas = useCallback(() => {
    api
      .get<TurmaProfano[]>(`dropdown/turmas`)
      .then(({ data: turmas }) => {
        setTurmaProfanoOptions(turmas);
      })
      .catch((error) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar as opções de turmas.",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [showToast]);

  const loadProfano = useCallback(() => {
    setLoading(true);
    api
      .get<ProfanoForm>(`profanos/detalhes/${id}`)
      .then(({ data: profano }) => {
        setProfanoInicial(profano);
        if (profano.informacoes && profano.informacoes.nome) setNome(profano.informacoes.nome);
        if (profano.informacoes) setInformacoes(profano.informacoes);
        if (profano.sindicancias) setSindicancias(profano.sindicancias);
        if (profano.anexos) setAnexos(profano.anexos);
        loadSindicantes();
        loadTurmas();
      })
      .catch((error) => {
        showToast({
          type: "error",
          title: "Não foi possível carregar o profano",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
        // eslint-disable-next-line no-console
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, [id, loadSindicantes, loadTurmas, showToast]);

  const salvarProfano = useCallback(() => {
    setLoadingSalvar(true);
    const sindicanciasToSend = sindicancias.map((sindicancia) => {
      if (sindicancia.uuid.includes("fake@")) {
        return {
          idSindicancia: sindicancia.id,
          idSindicante: sindicancia.sindicante?.id,
          dtLimite:
            sindicancia.dtLimite && !_.isArray(sindicancia.dtLimite)
              ? sindicancia.dtLimite?.toISOString().split("T")[0]
              : undefined,
        };
      }
      return {
        idSindicancia: sindicancia.id,
        idSindicante: sindicancia.sindicante?.id,
        dtLimite: sindicancia.dtLimite,
      };
    });
    api
      .put(`profanos/detalhes/${id}`, {
        sindicancias: sindicanciasToSend,
        informacoes: {
          situacao: informacoes.situacao,
          turma: informacoes.turma,
        },
        anexos,
      })
      .then(() => {
        showToast({ title: "Sucesso!", type: "success", description: "O profano foi editado e salvo." });
        history.push("/profano");
      })
      .catch((err) => {
        if (err.response?.data?.titulo?.includes("existe no sistema, por favor informe um diferente")) {
          showToast({
            type: "error",
            title: "O candidato já está cadastrado no sistema da GLOMAM.",
            description: "Você pode verificar diretamente na interface do Potência.",
          });
        } else handleError({ error: err, action: "atualizar profano", knownErrors: profanoErrors });
      })
      .finally(() => setLoadingSalvar(false));
  }, [anexos, handleError, history, id, informacoes.situacao, informacoes.turma, showToast, sindicancias]);

  const redefinirProfano = useCallback(() => {
    loadProfano();
  }, [loadProfano]);

  const onNewSindicancia = useCallback(
    (sindicancia: SindicanciaProfano) => {
      const newSindicancias = _.cloneDeep(sindicancias);
      newSindicancias.unshift(sindicancia);
      setSindicancias(newSindicancias);
    },
    [sindicancias]
  );

  const onDeleteSindicancia = useCallback(
    (sindicanciaId: string) => {
      const newSindicancias = _.cloneDeep(sindicancias);
      const indexFound = newSindicancias.findIndex((sindicancia) => sindicancia.uuid === sindicanciaId);
      if (indexFound >= 0) {
        newSindicancias.splice(indexFound, 1);
        setSindicancias(newSindicancias);
      }
    },
    [sindicancias]
  );

  const onAnexoChange = useCallback(
    (anexoId: string, anexo: AnexoProfano) => {
      const newAnexos = _.cloneDeep(anexos);
      const indexFound = newAnexos.findIndex((anex) => anex.uuid === anexoId);
      if (indexFound >= 0) {
        newAnexos[indexFound] = anexo;
        setAnexos(newAnexos);
      }
    },
    [anexos]
  );

  const onUpdateSindicancia = useCallback(
    (sindicanciaId: string, sindicancia: SindicanciaProfano) => {
      const newSindicancias = _.cloneDeep(sindicancias);
      const indexFound = newSindicancias.findIndex((sind) => sind.uuid === sindicanciaId);
      if (indexFound >= 0) {
        newSindicancias[indexFound] = sindicancia;
        setSindicancias(newSindicancias);
      }
    },
    [sindicancias]
  );

  const isDirty = useCallback(() => {
    return (
      !_.isEmpty(profanoInicial) &&
      (!_.isEqual(sindicancias, profanoInicial.sindicancias) ||
        !_.isEqual(informacoes, profanoInicial.informacoes) ||
        !_.isEqual(anexos, profanoInicial.anexos))
    );
  }, [anexos, informacoes, profanoInicial, sindicancias]);

  const checkSituacao = useCallback(() => {
    if (informacoes.situacao === "INICIADO") {
      confirmDialog({
        message: "Após a mudança, a situação não poderá ser alterada.",
        header: "Mudar situação?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Não",
        acceptLabel: "Sim",
        accept: () => salvarProfano(),
        reject: () => redefinirProfano(),
      });
    } else if (informacoes.situacao === "LIDO") {
      confirmDialog({
        message: "Os dados do Profano serão enviados para a GLOMAM e esta operação não poderá ser desfeita.",
        header: "Deseja realmente aprovar o candidato ?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Não",
        acceptLabel: "Sim",
        accept: () => salvarProfano(),
        reject: () => redefinirProfano(),
      });
    } else {
      salvarProfano();
    }
  }, [informacoes.situacao, salvarProfano, redefinirProfano]);

  useEffect(() => {
    if (location.state && location.state.nome) {
      setNome(location.state.nome);
    }
    loadProfano();
  }, [id, loadProfano, location.state]);

  return (
    <SimpleEntityListPage showTopBar routeBack="/profano" isFormPage loading={loading}>
      {nome && <h1 className="header-h1-text p-mb-3 p-mt-1">Profano {nome}</h1>}

      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="modelo-container">
        <TabPanel header="Informações">
          <InformacoesProfano
            loading={loading}
            informacoes={informacoes}
            dropdownSituacaoProfanoOptions={situacaoProfanoOptions}
            dropdownTurmaProfanoOptions={turmaProfanoOptions}
            onSituacaoChange={(e) => {
              const cloneInformacao = _.cloneDeep(informacoes);
              cloneInformacao.situacao = e;
              setInformacoes(cloneInformacao);
            }}
            onTurmaChange={(e) => {
              const cloneInformacao = _.cloneDeep(informacoes);
              cloneInformacao.turma = e;
              setInformacoes(cloneInformacao);
            }}
          />
        </TabPanel>
        <TabPanel header="Anexos">
          <AnexosProfano loading={loading} anexos={anexos} onChange={onAnexoChange} profanoUuid={id} />
        </TabPanel>
        <TabPanel header="Sindicâncias">
          <SindicanciasProfano
            loading={loading}
            profanoUuid={id}
            sindicancias={sindicancias}
            onAdd={onNewSindicancia}
            onRemove={onDeleteSindicancia}
            onUpdate={onUpdateSindicancia}
            sindicantesOptions={sindicantesOptions}
            loadingSindicantesOptions={loadingSindicantes}
          />
        </TabPanel>
        {/* <TabPanel header="Escrutínio">Escrutínio</TabPanel> */}
      </TabView>
      {isDirty() && (
        <FloatingSave
          saveCommand={checkSituacao}
          resetCommand={redefinirProfano}
          disabled={!isDirty()}
          loadingOnSave={loadingSalvar}
        />
      )}
    </SimpleEntityListPage>
  );
};

export default ProfanoFormPage;
