/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { ColumnProps } from "primereact/column";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

import { confirmDialog } from "primereact/confirmdialog";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import { LodgeWebhookDto } from "../types";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import { lodgeWebhookErrors } from "../../../errors/lodgeWebhook";

const WebhookManagementPage: React.FC = () => {
  const dt = useRef<DataTable>(null);

  const history = useHistory();
  const { showToast } = useToast();
  const { handleError } = useError();

  const [loading, setLoading] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [lodgeWithWebhook, setLodgeWithWebhook] = useState<LodgeWebhookDto[]>([]);

  const onGlobalFilterChange = useCallback((filterValue) => {
    setGlobalFilter(filterValue);
  }, []);

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<LodgeWebhookDto[]>("webhook-asaas")
      .then(({ data }) => {
        setLodgeWithWebhook(data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os webhooks",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showToast]);

  const excludeWebhook = useCallback(
    (data: LodgeWebhookDto) => {
      api
        .delete(`webhook-asaas/${data.id}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "O webhook foi removido." });
          loadData();
        })
        .catch((err) => {
          handleError({ error: err, action: "remover webhook", knownErrors: lodgeWebhookErrors });
        });
    },
    [handleError, loadData, showToast]
  );

  const confirmDelete = useCallback(
    (data: LodgeWebhookDto) => {
      confirmDialog({
        header: "Remover Webhook?",
        message: "Após a remoção o item não poderá ser recuperado.",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Remover",
        accept: () => excludeWebhook(data),
        reject: () => {},
      });
    },
    [excludeWebhook]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: LodgeWebhookDto) => {
      return (
        <>
          <Button
            type="button"
            className="p-row-editor-init p-link "
            icon="pi pi-eye"
            tooltip="Detalhes do webhook"
            tooltipOptions={{ position: "top", style: { width: 80, textAlign: "center" } }}
            onClick={() => {
              history.push(`/lodgeWebhookManagement/${data.id}`);
            }}
          />
          <Button
            type="button"
            className="p-ml-3 balandrau-exclude-button"
            icon="pi pi-trash"
            onClick={() => {
              confirmDelete(data);
            }}
          />
        </>
      );
    },
    [confirmDelete, history]
  );

  const copyToClipboard = useCallback(
    (assasKey) => {
      navigator.clipboard.writeText(assasKey);
      showToast({
        type: "info",
        title: "Valor copiado",
        description: "Valor da chave copiado para sua área de transferência",
      });
    },
    [showToast]
  );

  const columns = useMemo(
    (): ColumnProps[] => [
      {
        field: "numeroLoja",
        header: "Nome da Loja",
        sortable: true,
        style: { verticalAlign: "middle" },
        align: "left",
        body: (rowData: LodgeWebhookDto) => `${String(rowData.numeroLoja).padStart(2, "0")} - ${rowData.nomeLoja}`,
      },
      {
        field: "asaasApiKey",
        header: "Asaas api key",
        sortable: true,
        style: { verticalAlign: "middle" },
        align: "left",
        body: ({ asaasApiKey }: LodgeWebhookDto) => (
          <span onClick={() => copyToClipboard(asaasApiKey)}>{`...${asaasApiKey.substring(6, 56)}...`}</span>
        ),
      },
      {
        field: "asaasWebhookId",
        header: "Asaas webhook id",
        sortable: true,
        style: { verticalAlign: "middle" },
        align: "left",
        body: ({ asaasWebhookId }: LodgeWebhookDto) =>
          asaasWebhookId ? (
            <span onClick={() => copyToClipboard(asaasWebhookId)}>{`...${asaasWebhookId?.substring(6, 56)}...`}</span>
          ) : (
            ""
          ),
      },
      {
        body: editDeleteButtonsBodyTemplate,
        style: { width: "50px", textAlign: "center", verticalAlign: "middle" },
      },
    ],
    [copyToClipboard, editDeleteButtonsBodyTemplate]
  );

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <SimpleEntityPage
      showListHeader
      onGlobalFilterChange={(value) => onGlobalFilterChange(value)}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
      newButtonLabel="Novo webhook"
      onNewButtonCLick={() => {
        history.push(`/lodgeWebhookManagement/novo`);
      }}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={lodgeWithWebhook}
        columns={columns}
        globalFilter={globalFilter}
        sortField="numeroLoja"
        sortOrder={1}
      />
    </SimpleEntityPage>
  );
};

export default WebhookManagementPage;
