import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";

import { ColumnProps } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import SimpleEntityDatatable from "../../../components/SimpleEntityDatatable";
import { MobileVersionDTO } from "../types";
import api from "../../../services/api";
import { useError } from "../../../hooks/error";
import { mobileVersionErrors } from "../../../errors/mobile-version";
import { useToast } from "../../../hooks/toast";

const MobileVersionPage: React.FC = () => {
  const dt = useRef<DataTable>(null);

  const [mobileVersions, setMobileVersions] = useState<MobileVersionDTO[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [globalFilter, setGlobalFilter] = useState<string>("");

  const { showToast } = useToast();
  const { handleError } = useError();

  const history = useHistory();

  const newButton = useCallback(() => {
    history.push("/mobile-version/novo");
  }, [history]);

  const onFilterChange = useCallback((value) => setGlobalFilter(value), []);

  const editVersion = useCallback(
    (uuid: string) => {
      history.push({ pathname: `/mobile-version/${uuid}` });
    },
    [history]
  );

  const loadVersions = useCallback(() => {
    setLoading(true);
    api
      .get("mobile-version")
      .then(({ data }) => {
        setMobileVersions(data);
      })
      .catch((err) => {
        handleError({ action: "carregar versões", error: err, knownErrors: mobileVersionErrors });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError]);

  const onDeleteVersion = useCallback(
    (data: MobileVersionDTO) => {
      api
        .delete(`mobile-version/${data.uuid}`)
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A versão foi excluída" });
          loadVersions();
        })
        .catch((err) => {
          handleError({ error: err, action: "excluir versão", knownErrors: mobileVersionErrors });
        });
    },
    [handleError, loadVersions, showToast]
  );

  const excludeVersion = useCallback(
    (data: MobileVersionDTO) => {
      confirmDialog({
        message: "Após a exclusão o item não poderá ser recuperado.",
        header: "Excluir versão?",
        icon: "pi pi-question-circle",
        focusOnShow: false,
        rejectLabel: "Manter",
        acceptLabel: "Excluir",
        accept: () => onDeleteVersion(data),
        reject: () => {},
      });
    },
    [onDeleteVersion]
  );

  const editDeleteButtonsBodyTemplate = useCallback(
    (data: MobileVersionDTO) => {
      return (
        <>
          <Button
            type="button"
            className="p-ml-1 p-row-editor-init p-link "
            icon="pi pi-pencil"
            onClick={() => editVersion(data.uuid)}
          />
          <Button
            type="button"
            className="p-ml-1 balandrau-exclude-button "
            icon="pi pi-trash"
            onClick={() => {
              excludeVersion(data);
            }}
          />
        </>
      );
    },
    [editVersion, excludeVersion]
  );

  const columns = useMemo(
    (): ColumnProps[] => [
      {
        field: "version",
        header: "Versão",
        sortable: true,
        style: { textAlign: "left", verticalAlign: "middle" },
        className: "p-text-nowrap p-text-truncate",
      },
      {
        align: "center",
        body: editDeleteButtonsBodyTemplate,
        style: { width: "130px", verticalAlign: "middle" },
      },
    ],
    [editDeleteButtonsBodyTemplate]
  );

  useEffect(() => {
    loadVersions();
  }, [loadVersions]);

  return (
    <SimpleEntityListPage
      showListHeader
      newButtonLabel="Nova versão"
      onNewButtonCLick={newButton}
      onGlobalFilterChange={onFilterChange}
      loading={loading}
      showExportCsvButton
      datatableRef={dt}
    >
      <SimpleEntityDatatable
        datatableRef={dt}
        value={mobileVersions}
        columns={columns}
        globalFilter={globalFilter}
        sortField="version"
        sortOrder={-1}
      />
    </SimpleEntityListPage>
  );
};

export default MobileVersionPage;
