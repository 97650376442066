import _ from "lodash";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Checkbox, CheckboxChangeParams } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { Tooltip } from "primereact/tooltip";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import CustomFormField from "../../../components/CustomFormField";
import { situacoesMap } from "../../../components/IrmaoSituacaoComponent";
import Loading from "../../../components/Loading";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import { notificacaoErrors } from "../../../errors/notificacao";
import { useError } from "../../../hooks/error";
import { useToast } from "../../../hooks/toast";
import api from "../../../services/api";
import { formatDateToStringForSend } from "../../../utils/formatDateToStringForSend";
import { IrmaoNotificacaoDTO, NotificacaoDTOEdit } from "../utils";

interface Params {
  id: string;
}

interface SMSLimite {
  servico: string;
  credito: string;
}

const NotificacaoFormPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);
  const [loadingSaldo, setLoadingSaldo] = useState(false);
  const [dadosIniciais, setDadosIniciais] = useState<NotificacaoDTOEdit>();

  const [irmaos, setIrmaos] = useState<IrmaoNotificacaoDTO[]>([]);
  const [formaEnvio, setFormaEvio] = useState<string[]>([]);
  // const [quando, setQuando] = useState();
  const [titulo, setTitulo] = useState<string>("");
  const [resumoMensagem, setResumoMensagem] = useState<string>("");
  const [mensagem, setMensagem] = useState<string>("");

  const [agendarEnvio, setAgendarEnvio] = useState(true);
  const [dtEnvio, setDtEnvio] = useState<Date | Date[] | undefined>(new Date(Date.now()));

  const [quantidadeSmsRestante, setQuantidadeSmsRestante] = useState<string>();

  const [dropdownIrmaosOptions, setDropdownIrmaosOptions] = useState([]);

  const [isDirty, setIsDirty] = useState(true);

  const history = useHistory();
  const { showToast } = useToast();
  const { handleError } = useError();
  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  const { id } = useParams<Params>();

  const onFormaChange = useCallback(
    (e: CheckboxChangeParams) => {
      const selectedFormaEnvio = _.cloneDeep(formaEnvio);

      if (e.checked) {
        selectedFormaEnvio.push(e.value);
      } else selectedFormaEnvio.splice(selectedFormaEnvio.indexOf(e.value), 1);

      setFormaEvio(selectedFormaEnvio);
    },
    [formaEnvio]
  );

  const validacao = useCallback(() => {
    return (
      !_.isEmpty(irmaos) &&
      !_.isEmpty(formaEnvio) &&
      !!dtEnvio &&
      !_.isEmpty(titulo) &&
      !_.isEmpty(resumoMensagem) &&
      !_.isEmpty(mensagem)
    );
  }, [dtEnvio, formaEnvio, irmaos, mensagem, resumoMensagem, titulo]);

  const sendNotification = useCallback(() => {
    if (!loadingSalvar && validacao()) {
      setLoadingSalvar(true);
      api
        .post("mensagens", {
          titulo,
          resumo: resumoMensagem,
          corpo: mensagem,
          enviadoViaEmail: !!formaEnvio.find((forma) => forma === "EMAIL"),
          enviadoViaPushNotification: !!formaEnvio.find((forma) => forma === "PUSH_NOTIFICATION"),
          enviadoViaSms: !!formaEnvio.find((forma) => forma === "SMS"),
          dtAgendamento: dtEnvio,
          irmaos,
        })
        .then(() => {
          showToast({ title: "Sucesso!", type: "success", description: "A notificação foi criada." });
          history.push("/notificacao");
        })
        .catch((err) => {
          handleError({ error: err, action: "criar cobrança", knownErrors: notificacaoErrors });
        })
        .finally(() => {
          setLoadingSalvar(false);
        });
    }
  }, [
    dtEnvio,
    formaEnvio,
    handleError,
    history,
    irmaos,
    loadingSalvar,
    mensagem,
    resumoMensagem,
    showToast,
    titulo,
    validacao,
  ]);

  const yearNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const monthNavigatorTemplate = (e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const loadDropdown = useCallback(() => {
    api
      .get("dropdown/notificacoesIrmaos")
      .then(({ data }) => {
        setDropdownIrmaosOptions(data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os irmãos",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {});
  }, [showToast]);

  const setarFormaEnvio = useCallback((dados: NotificacaoDTOEdit) => {
    const enviadoViaPushNotification = dados.enviadoViaPushNotification ? "PUSH_NOTIFICATION" : "";
    const enviadoViaSms = dados.enviadoViaSms ? "SMS" : "";

    let enviadoNasPlataformas: string[] = [];
    if (!_.isEmpty(enviadoViaPushNotification)) {
      enviadoNasPlataformas = [enviadoViaPushNotification];
    }
    if (!_.isEmpty(enviadoViaSms)) {
      enviadoNasPlataformas = [...enviadoNasPlataformas, enviadoViaSms];
    }
    setFormaEvio(enviadoNasPlataformas);
  }, []);

  const loadSaldo = useCallback(() => {
    setLoadingSaldo(true);
    api
      .get<SMSLimite>(`mensagens/ver-saldo`)
      .then(({ data }) => {
        setQuantidadeSmsRestante(`${+data.credito}`);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar o saldo de SMS",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoadingSaldo(false);
      });
  }, [showToast]);

  const loadData = useCallback(() => {
    setLoading(true);

    api
      .get<NotificacaoDTOEdit>(`mensagens/${id}`)
      .then(({ data }) => {
        setDadosIniciais(data);
        setIrmaos(data.irmaos);
        setDtEnvio(new Date(data.dtAgendamento));
        setAgendarEnvio(new Date(Date.now()) > new Date(data.dtAgendamento));
        setTitulo(data.titulo);
        setResumoMensagem(data.resumo);
        setMensagem(data.corpo);
        setQuantidadeSmsRestante(data.limiteSms);

        setarFormaEnvio(data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar a notificação",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, setarFormaEnvio, showToast]);

  useEffect(() => {
    loadDropdown();
    loadSaldo();
    if (id !== "novo") {
      loadData();
    }
  }, [id, loadData, loadDropdown, loadSaldo]);

  return (
    <SimpleEntityPage showTopBar routeBack="/notificacao" isFormPage loading={loading}>
      <div className="header-notificacao p-mb-5">
        <p className="header-notificacao-text">Enviar SMS e Notificação</p>
      </div>
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              icon="fa-solid fa-user"
              htmlForDescription="irmaos"
              description="Irmãos"
              className="p-mb-4"
              labelWidth="4"
              inputBody={
                <MultiSelect
                  placeholder="Selecione"
                  showClear
                  dataKey="uuid"
                  optionLabel="nome"
                  options={dropdownIrmaosOptions}
                  value={irmaos}
                  itemTemplate={(irmao: IrmaoNotificacaoDTO) => (
                    <div style={{ width: 400, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <span className="p-text-nowrap p-text-truncate" style={{ marginRight: "auto" }}>
                        {irmao.nome}
                      </span>
                      <span>
                        {irmao.situacao === situacoesMap.REMIDO.situacao && (
                          <Tag className={situacoesMap.REMIDO.class} value={situacoesMap.REMIDO.label} />
                        )}
                      </span>
                    </div>
                  )}
                  onChange={(e) => {
                    setIrmaos(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.irmao;
                    setErrors(errorsLocal);
                    if (!_.isEqual(dadosIniciais?.irmaos, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  emptyFilterMessage="Nenhum irmão encontrado"
                  selectedItemsLabel="{0} irmãos selecionados"
                  maxSelectedLabels={3}
                  filter
                  filterBy="nome"
                  disabled={id !== "novo"}
                />
              }
              required
              errorMessage={errors.irmaos}
            />
            <CustomFormField
              icon="fas fa-share"
              htmlForDescription="forma-envio"
              description="Forma de envio"
              className="p-mb-4"
              labelWidth="4"
              inputBody={
                <div className="p-d-flex p-ai-start" style={{ paddingTop: 2, paddingBottom: 2 }}>
                  <div className="sms-container-option">
                    <div className="notificacao-option-forma-envio p-d-flex p-ai-center">
                      <Checkbox
                        inputId="sms"
                        name="sms"
                        value="SMS"
                        className="p-mr-1"
                        onChange={onFormaChange}
                        checked={formaEnvio.indexOf("SMS") !== -1}
                        disabled
                      />
                      <label htmlFor="sms">SMS</label>

                      <i className="pi pi-info-circle sms-icon p-ml-1" />
                      <Tooltip
                        target=".sms-icon"
                        position="top"
                        content="SMSs possuem um limite para o uso e tem custos para envio, porém, mesmo aqueles irmãos que não possuem o Balandrau instalado no celular receberão sua mensagem."
                        style={{ width: 275 }}
                      />
                    </div>
                    <span className="sms-quantidade">
                      SMSs restantes:{" "}
                      {loadingSaldo ? (
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: "10px" }} />
                      ) : (
                        quantidadeSmsRestante
                      )}
                    </span>
                  </div>

                  <div className="notificacao-option-forma-envio">
                    <Checkbox
                      inputId="notificacao"
                      name="notificacao"
                      value="PUSH_NOTIFICATION"
                      className="p-mr-1"
                      onChange={onFormaChange}
                      checked={formaEnvio.indexOf("PUSH_NOTIFICATION") !== -1}
                      disabled={id !== "novo"}
                    />
                    <label htmlFor="notificacao">Notificação</label>

                    <i className="pi pi-info-circle push-icon p-ml-1" />
                    <Tooltip
                      target=".push-icon"
                      position="top"
                      content="Notificações não possuem limite para uso e tem envio gratuito, porém somente aqueles irmãos que possuem o Balandrau instalado no celular receberão sua mensagem."
                      style={{ width: 275 }}
                    />
                  </div>
                </div>
              }
              required
              errorMessage={errors.formaEnvio}
            />
            <CustomFormField
              icon="fas fa-clock"
              htmlForDescription="forma-envio"
              description="Quando"
              className="p-mb-4"
              labelWidth="4"
              inputBody={
                <>
                  {id === "novo" && (
                    <div className="notificacao-option-forma-envio p-mb-3">
                      <Checkbox
                        className="p-mr-1"
                        name="agendar-envio"
                        inputId="agendar-envio"
                        checked={!agendarEnvio}
                        onChange={(e) => {
                          setAgendarEnvio(!e.checked);
                          if (e.checked === false) setDtEnvio(new Date(Date.now()));
                        }}
                      />
                      <label htmlFor="agendar-envio">Agendar envio</label>
                    </div>
                  )}

                  <div className="p-d-flex p-ai-center" style={{ paddingTop: 2, paddingBottom: 2 }}>
                    <Calendar
                      id="dtIniciacao"
                      placeholder="DD/MM/AAAA"
                      value={dtEnvio}
                      disabled={agendarEnvio || id !== "novo"}
                      onChange={(e) => {
                        setDtEnvio(e.value);
                        if (!_.isEqual(dadosIniciais?.dtAgendamento, formatDateToStringForSend(e.value?.toString()))) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      showTime
                      stepMinute={5}
                      monthNavigator
                      yearNavigator
                      yearRange="1800:2050"
                      monthNavigatorTemplate={monthNavigatorTemplate}
                      yearNavigatorTemplate={yearNavigatorTemplate}
                      locale="pt-br"
                      showIcon
                    />
                  </div>
                </>
              }
              required
              errorMessage={errors.formaEnvio}
            />

            <CustomFormField
              icon="fa-solid fa-heading"
              labelWidth="4"
              htmlForDescription="titulo"
              description="Título"
              inputBody={
                <InputText
                  id="titulo"
                  value={titulo || ""}
                  type="text"
                  placeholder="Título"
                  onChange={(e) => {
                    setTitulo(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.titulo;
                    setErrors(errorsLocal);
                    if (!_.isEqual(dadosIniciais?.titulo, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  maxLength={50}
                  disabled={id !== "novo"}
                />
              }
              required
              errorMessage={errors.titulo}
            />
            <CustomFormField
              icon="fa-solid fa-mobile-screen-button"
              labelWidth="4"
              htmlForDescription="resumo-mensagem"
              description="Resumo da mensagem"
              info={{
                tooltip: "Este campo será utilizado na notificação enviada para o celular do irmão",
                id: "resumo-mensagem",
              }}
              inputBody={
                <InputTextarea
                  id="resumo-mensagem"
                  value={resumoMensagem || ""}
                  // type="text"
                  rows={3}
                  placeholder="Resumo da mensagem"
                  onChange={(e) => {
                    setResumoMensagem(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.resumo;
                    setErrors(errorsLocal);
                    if (!_.isEqual(dadosIniciais?.resumo, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  maxLength={140}
                  disabled={id !== "novo"}
                />
              }
              required
              errorMessage={errors.resumo}
            />
            <CustomFormField
              icon="fa-solid fa-message"
              labelWidth="4"
              htmlForDescription="mensagem"
              description="Mensagem"
              info={{
                tooltip:
                  "Este campo será utilizado no corpo da mensagem, tanto para o SMS quanto para a notificação do celular",
                id: "mensagem",
              }}
              inputBody={
                <InputTextarea
                  id="mensagem"
                  value={mensagem || ""}
                  rows={5}
                  placeholder="Corpo da mensagem"
                  onChange={(e) => {
                    setMensagem(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.mensagem;
                    setErrors(errorsLocal);
                    if (!_.isEqual(dadosIniciais?.corpo, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  // maxLength={140}
                  disabled={id !== "novo"}
                />
              }
              required
              errorMessage={errors.mensagem}
            />
          </div>
        </div>
      </div>
      {isDirty && (
        <>
          <Button
            className="button-send-notification"
            onClick={sendNotification}
            disabled={loadingSalvar || !validacao() || id !== "novo"}
          >
            <i className="fa-solid fa-align-right send-notification-icon-1" />
            <i className="fa-solid fa-envelope send-notification-icon-2" />
            <span className="send-notification-label">Enviar notificação</span>

            {loadingSalvar && (
              <div style={{ marginLeft: 20, marginRight: 5 }}>
                <Loading isLoading color="#fff" size={10} />
              </div>
            )}
          </Button>
        </>
      )}
    </SimpleEntityPage>
  );
};

export default NotificacaoFormPage;
