import React, { useCallback, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";

import SimpleEntityPage from "../../../components/SimpleEntityPage";
import CustomFormField from "../../../components/CustomFormField";
import { LodgeDataDto, LodgeWebhookFormDto, LojaDropdown } from "../types";
import api from "../../../services/api";
import FloatingSave from "../../../components/FloatingSave";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import { lodgeWebhookErrors } from "../../../errors/lodgeWebhook";

interface Params {
  id: string;
}

const WebhookManagementFormPage: React.FC = () => {
  const { id } = useParams<Params>();

  const history = useHistory();
  const { showToast } = useToast();
  const { handleError } = useError();

  const [loading, setLoading] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);

  const [webhookInicial, setWebhookInicial] = useState<LodgeWebhookFormDto>({} as LodgeWebhookFormDto);

  const [asaasApiKey, setAsaasApiLey] = useState<string>("");

  const [loja, setLoja] = useState({} as LojaDropdown);
  const [lojaOptions, setLojaOptions] = useState<LojaDropdown[]>([]);
  const [loadingDropdown, setLoadingDropdown] = useState(false);

  const [email, setEmail] = useState("");

  const [asaasWebhookId, setAsaasWebhookId] = useState("");
  const [balandrauToken, setBalandrauToken] = useState("");

  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState<{ [errorKey: string]: string }>({});

  const loadDropdownInfo = useCallback(() => {
    setLoadingDropdown(true);
    api
      .get<LojaDropdown[]>(`dropdown/lojas-sem-webhook`)
      .then(({ data }) => {
        setLojaOptions(
          data.map((l) => {
            return {
              ...l,
              labelToShow: `${String(l.numero).padStart(2, "0")} - ${l.nome}`,
            };
          })
        );
      })
      .catch(() => {})
      .finally(() => {
        setLoadingDropdown(false);
      });
  }, []);

  const validarCampos = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    if (_.isEmpty(asaasApiKey)) errorsLocal.asaasApiKey = "Asaas api key é um campo obrigatório";
    if (_.isEmpty(loja)) errorsLocal.loja = "Selecione ao menos uma loja";
    if (id === "novo" && _.isEmpty(email)) errorsLocal.email = "Email é um campo obrigatório";

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [asaasApiKey, email, id, loja]);

  const salvarWebhook = useCallback(() => {
    const valid = validarCampos();
    if (valid) {
      const body = {
        idLoja: loja.numero,
        asaasApiKey,
        email,
      };

      setLoadingSalvar(true);

      if (id === "novo") {
        api
          .post("dados-loja-asaas", body)
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "O webhook foi criado." });
            history.push("/lodgeWebhookManagement");
          })
          .catch((err) => {
            handleError({ error: err, action: "criar webhook", knownErrors: lodgeWebhookErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [asaasApiKey, email, handleError, history, id, loja.numero, showToast, validarCampos]);

  const loadData = useCallback(() => {
    setLoading(true);
    api
      .get<LodgeDataDto>(`dados-loja-asaas/${id}`)
      .then(({ data }) => {
        const foundedLodge = {
          id: data.idLoja,
          labelToShow: `${String(data.lojaNumero).padStart(2, "0")} - ${data.lojaNome}`,
          nome: data.lojaNome,
          numero: data.lojaNumero,
        };
        setLojaOptions([foundedLodge]);

        setLoja(foundedLodge);

        setWebhookInicial({
          idLoja: data.idLoja,
          email: "",
          asaasApiKey: data.asaasApiKey,
        });

        setAsaasApiLey(data.asaasApiKey);

        setAsaasWebhookId(data.asaasWebhookId);

        setBalandrauToken(data.balandrauToken);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }, [id]);

  const redefinirWebhook = useCallback(() => {
    setIsDirty(false);
    if (id === "novo") {
      setAsaasApiLey("");
      setLoja({} as LojaDropdown);
      setEmail("");
    } else {
      loadData();
    }
  }, [id, loadData]);

  const isValid = useCallback(() => {
    const emailValid = id !== "novo" ? true : !_.isEmpty(email);
    return !_.isEmpty(asaasApiKey) && !_.isEmpty(loja) && emailValid;
  }, [asaasApiKey, email, id, loja]);

  useEffect(() => {
    if (id !== "novo") loadData();
    else loadDropdownInfo();
  }, [id, loadData, loadDropdownInfo]);

  return (
    <SimpleEntityPage showTopBar routeBack="/lodgeWebhookManagement" isFormPage loading={loading || loadingDropdown}>
      <div className="p-grid flex-row-center p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              icon="pi pi-key"
              htmlForDescription="asaasApiKey"
              description="Asaas api key"
              inputBody={
                id === "novo" ? (
                  <InputText
                    id="asaasApiKey"
                    value={asaasApiKey}
                    onChange={(e) => {
                      setAsaasApiLey(e.target.value);

                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.asaasApiKey;
                      setErrors(errorsLocal);
                      if (!_.isEqual(webhookInicial.asaasApiKey, e.target.value)) {
                        setIsDirty(true);
                      } else {
                        setIsDirty(false);
                      }
                    }}
                  />
                ) : (
                  <>{webhookInicial.asaasApiKey}</>
                )
              }
              required
              errorMessage={errors.asaasApiKey}
            />

            <CustomFormField
              icon="pi pi-building"
              htmlForDescription="loja"
              description="Loja"
              inputBody={
                id === "novo" ? (
                  <Dropdown
                    id="loja"
                    placeholder="Selecione"
                    options={lojaOptions}
                    optionLabel="labelToShow"
                    value={loja}
                    dataKey="numero"
                    disabled={id !== "novo"}
                    onChange={(e) => {
                      setLoja(e.value);

                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.loja;
                      setErrors(errorsLocal);

                      if (!_.isEqual(webhookInicial.idLoja, e.value.numero)) {
                        setIsDirty(true);
                      } else {
                        setIsDirty(false);
                      }
                    }}
                  />
                ) : (
                  loja.labelToShow
                )
              }
              required
              errorMessage={errors.loja}
            />

            {id === "novo" && (
              <CustomFormField
                icon="ba ba-email"
                htmlForDescription="email"
                description="Email"
                inputBody={
                  <InputText
                    id="email"
                    value={email}
                    type="email"
                    disabled={id !== "novo"}
                    onChange={(e) => {
                      setEmail(e.target.value);

                      const errorsLocal = _.cloneDeep(errors);
                      delete errorsLocal.tipo;
                      setErrors(errorsLocal);
                      if (!_.isEqual(webhookInicial.email, e.target.value)) {
                        setIsDirty(true);
                      } else {
                        setIsDirty(false);
                      }
                    }}
                  />
                }
                required
                errorMessage={errors.email}
              />
            )}

            {id !== "novo" && (
              <>
                <CustomFormField
                  icon="pi pi-key"
                  htmlForDescription="asaasWebhookId"
                  description="Asaas webhook id"
                  inputBody={<>{asaasWebhookId}</>}
                />
                <CustomFormField
                  icon="pi pi-key"
                  htmlForDescription="balandrauToken"
                  description="Balandrau token"
                  inputBody={<>{balandrauToken}</>}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {isDirty && (
        <FloatingSave
          saveCommand={salvarWebhook}
          resetCommand={redefinirWebhook}
          disabled={loadingSalvar || !isValid()}
          loadingOnSave={loadingSalvar}
        />
      )}
    </SimpleEntityPage>
  );
};

export default WebhookManagementFormPage;
