import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ProfanoInfo, TurmaProfano } from "../../pages/Profano/ProfanoFormPage";
import { convertDateToStringBR } from "../../utils/convertDates";
import CustomFormField from "../CustomFormField";
import PreviewSelectAvatar from "../PreviewSelectAvatar";
import { SituacaoProfano, SituacaoProfanoElement, situacoesProfanoMap } from "../ProfanoSituacaoComponent";
import SimpleEntityPage from "../SimpleEntityPage";
import api from "../../services/api";
import { useToast } from "../../hooks/toast";

interface Props {
  loading: boolean;
  informacoes: ProfanoInfo;
  dropdownSituacaoProfanoOptions: SituacaoProfanoElement[];
  dropdownTurmaProfanoOptions: TurmaProfano[];
  // eslint-disable-next-line no-unused-vars
  onSituacaoChange: (situacao: SituacaoProfano) => void;
  // eslint-disable-next-line no-unused-vars
  onTurmaChange: (turma: TurmaProfano) => void;
}
const InformacoesProfano: React.FC<Props> = ({
  loading,
  informacoes,
  dropdownSituacaoProfanoOptions,
  dropdownTurmaProfanoOptions,
  onSituacaoChange,
  onTurmaChange,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const situacaoInicial = useMemo(() => informacoes?.situacao, [informacoes?.situacao]);
  const PATH_FICHA_PROFANO = "/relatorios";

  const { showToast } = useToast();

  const openUrlNewTab = async () => {
    try {
      setIsLoading(true);

      const response = await api.post(
        `${PATH_FICHA_PROFANO}`,
        {
          nomeRelatorio: "novo_relatorio_formulario_iniciacao_profano",
          parametroBusca: informacoes.uuid,
        },
        {
          responseType: "blob",
        }
      );

      if (!response.data) {
        throw new Error("A API não retornou um PDF válido.");
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const pdfUrl = window.URL.createObjectURL(response.data);

      const opened = window.open(pdfUrl, "_blank", "noopener,noreferrer");
      if (opened) {
        opened.opener = null;
      }
    } catch (error: any) {
      showToast({
        type: "error",
        title: "Erro ao gerar a ficha",
        description: `Erro ao gerar a ficha: ${error.message}`,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const disabledOptionsOnSituacaoAprovado = useCallback(
    (option: SituacaoProfanoElement) => {
      if (
        situacaoInicial === situacoesProfanoMap.APROVADO.situacao ||
        situacaoInicial === situacoesProfanoMap.PAGO.situacao
      )
        return (
          option === situacoesProfanoMap.AGUARDANDO ||
          option === situacoesProfanoMap.PRONTO_PARA_LEITURA ||
          option === situacoesProfanoMap.LIDO ||
          option === situacoesProfanoMap.SINDICANCIA ||
          option === situacoesProfanoMap.ESCRUTINIO ||
          option === situacoesProfanoMap.APROVADO ||
          option === situacoesProfanoMap.REJEITADO
        );
      if (
        situacaoInicial === situacoesProfanoMap.LIDO.situacao ||
        situacaoInicial === situacoesProfanoMap.SINDICANCIA.situacao ||
        situacaoInicial === situacoesProfanoMap.ESCRUTINIO.situacao
      )
        return (
          option === situacoesProfanoMap.AGUARDANDO ||
          option === situacoesProfanoMap.PRONTO_PARA_LEITURA ||
          option === situacoesProfanoMap.LIDO
        );
      return false;
    },
    [situacaoInicial]
  );

  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  return (
    <>
      <SimpleEntityPage showTopBar={false} loading={loading} hidePagePadding>
        <div className="profano-informacoes">
          <div className="profano-informacoes-left p-grid p-col-7">
            <div className="profano-informacoes-button">
              <Button
                icon="fa-solid fa-file-pdf"
                label="Ficha de iniciação"
                onClick={() => openUrlNewTab()}
                loading={isLoading}
                // onClick={() => openUrlNewTab(`${PATH_FICHA_PROFANO}${informacoes.uuid}?token=false`)}
              />
            </div>
            <div className="profano-informacoes-form p-fluid p-col-12">
              <CustomFormField
                icon="fa-solid fa-users"
                htmlForDescription="turma"
                description="Turma"
                inputBody={
                  <Dropdown
                    placeholder="Selecione"
                    options={dropdownTurmaProfanoOptions}
                    value={informacoes.turma}
                    optionLabel="nome"
                    itemTemplate={(turma) => (
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>{turma.nome}</div>
                        <div>{convertDateToStringBR(turma.dtIniciacao)}</div>
                      </div>
                    )}
                    onChange={(e) => {
                      onTurmaChange(e.target.value);
                    }}
                  />
                }
              />
              <CustomFormField
                icon="fa-solid fa-project-diagram"
                htmlForDescription="situacao"
                description="Situação"
                inputBody={
                  <Dropdown
                    placeholder="Selecione"
                    options={dropdownSituacaoProfanoOptions}
                    value={informacoes?.situacao}
                    optionValue="situacao"
                    optionDisabled={(option) => disabledOptionsOnSituacaoAprovado(option)}
                    disabled={situacaoInicial === "INICIADO"}
                    onChange={(e) => {
                      onSituacaoChange(e.target.value);
                    }}
                    scrollHeight="500px"
                  />
                }
              />
            </div>
          </div>
          <div className="profano-informacoes-right p-grid p-col-5">
            <PreviewSelectAvatar
              className="profano-informacoes-image"
              width="200px"
              height="200px"
              src={informacoes?.foto}
              editing={false}
            />
          </div>
        </div>
      </SimpleEntityPage>
    </>
  );
};

export default InformacoesProfano;
