import React from "react";
import { Switch, Route as NativeRouter, useRouteMatch } from "react-router-dom";

import Route from "./Route";

import MainContainer from "../components/MainContainer";

import SignIn from "../pages/SignInPage";
import Dashboard from "../pages/DashboardPage";
import Page404 from "../pages/404Page";

import IrmaoPage from "../pages/Irmao/IrmaoPage";
import IrmaoFormPage from "../pages/Irmao/IrmaoFormPage";
import CobrancaPage from "../pages/Cobranca/CobrancaPage";
import CobrancaFormPage from "../pages/Cobranca/CobrancaFormPage";
import UsuarioFormPage from "../pages/ControleDeAcesso/Usuario/UsuarioFormPage";
import UsuarioPage from "../pages/ControleDeAcesso/Usuario/UsuarioPage";
import FormProfanoPage from "../pages/FormProfano/FormProfanoPage";
import ProfanoSignInPage from "../pages/FormProfano/ProfanoSignInPage";
import EscrutinioPage from "../pages/Escrutinio/EscrutinioPage";
import EscrutinioFormPage from "../pages/Escrutinio/EscrutinioFormPage";
import CrudProfanoPage from "../pages/FormProfano/Crud/ProfanoPage";
import IniciacaoProfanoFormPage from "../pages/FormProfano/Crud/ProfanoFormPage";
import VotacaoEscrutinioPage from "../pages/VotacaoEscrutinioPage";
import EscrutinioDetails from "../pages/Escrutinio/EscrutinioDetails";
import ProfanoPage from "../pages/Profano/ProfanoPage";
import ProfanoFormPage from "../pages/Profano/ProfanoFormPage";
import TurmaPage from "../pages/Turma/TurmaPage";
import TurmaFormPage from "../pages/Turma/TurmaFormPage";
import NotificacaoFormPage from "../pages/Notificacao/NotificacaoFormPage";
import NotificaoPage from "../pages/Notificacao/NotificacaoPage";
import RespostaSMSPage from "../pages/RespostaSMS/RespostaSMSPage";
import ConviteFiliacaoPage from "../pages/FormProfano/Filiacao/ConviteFiliacaoPage";
import FiliandoPage from "../pages/Filiando/FiliandoPage";
import FiliandoFormPage from "../pages/Filiando/FiliandoFormPage";
import PecasArquiteturaPage from "../pages/PecasArquitetura/PecasArquiteturaPage";
import PecasArquiteturaFormPage from "../pages/PecasArquitetura/PecasArquiteturaFormPage";
import FilePagePecasArquitetura from "../components/FilePagePecasArquitetura";
import FilePageFichaFiliando from "../components/FilePageFichaFiliando";
import FilePageInformacoesProfano from "../components/FilePageInformacoesProfano";
import FilePageFichaSindicancia from "../components/FilePageFichaSindicancia";
import FilePageFichaSindicanciaPreenchida from "../components/FilePageFichaSindicanciaPreenchida";
import FilePageTurmaProfano from "../components/FilePageTurmaProfano";
import FilePageTestamento from "../components/FilePageTestamento";
import FilePageAnexo from "../components/FilePageAnexo";
import FilePageDetalhesFiliandos from "../components/FilePageDetalhesFiliandos";
import FilePageDetalhesProfanos from "../components/FilePageDetalhesProfanos";
import FilePageDetalhesFiliandosMerge from "../components/FilePageDetalhesFiliandosMerge";
import FilePageDetalhesProfanoMerge from "../components/FilePageDetalhesProfanoMerge";
import FilePageDeclaracaoVontade from "../components/FilePageDeclaracaoVontade";
import PublicacaoPage from "../pages/Publicacao/PublicacaoPage";
import PublicacaoFormPage from "../pages/Publicacao/PublicacaoFormPage";
import ConviteGlomam from "../pages/DELETAR/conviteGlomam";
import ConfirmacaoParticipacaoPage from "../pages/DELETAR/ConfirmacaoParticipacaoPage";
import FilePageRecibo from "../components/FilePageRecibo";
import { useAuth } from "../hooks/auth";
import DashboardWithOutIrmao from "../pages/DashboardWithOutIrmaoPage";
import AcervoPage from "../pages/Acervo/AcervoPage";
import AcervoFormPage from "../pages/Acervo/AcervoFormPage";
import ExemplaresFormPage from "../pages/Acervo/Exemplares";
import LeitoresPage from "../pages/Biblioteca/Leitores/LeitoresPage";
import LeitoresFormPage from "../pages/Biblioteca/Leitores/LeitoresFormPage";
import EmprestimoPage from "../pages/Biblioteca/Emprestimo/EmprestimoPage";
import NominataPage from "../pages/Nominata/NominataPage";
import NominataFormPage from "../pages/Nominata/NominataFormPage";
import DatasComemorativasPage from "../pages/DatasComemorativas/DatasComemorativasPage";
import DatasComemorativasFormPage from "../pages/DatasComemorativas/DatasComemorativasFormPage";
import ExcluirContaPage from "../pages/ExcluirContaPage";
import PodcastPage from "../pages/Podcast/Programa/PodcastPage";
import PodcastFormPage from "../pages/Podcast/Programa/PodcastFormPage";
import TemporadaPage from "../pages/Podcast/Temporada/TemporadaPage";
import TemporadaFormPage from "../pages/Podcast/Temporada/TemporadaFormPage";
import EpisodioPage from "../pages/Podcast/Episodio/EpisodioPage";
import EpisodioFormPage from "../pages/Podcast/Episodio/EpisodioFormPage";
import FilePageDetalhesProfanoSindicanciasMerge from "../components/FilePageDetalhesProfanoSindicanciaMerge";
import SignDocumentPage from "../pages/FormProfano/SignDocumentPage";
import MobileVersionPage from "../pages/MobileVersion/MobileVersionPage";
import MobileVersionForm from "../pages/MobileVersion/MobileVersionForm";
import WebhookManagementPage from "../pages/WebhookManagement/WebhookManagementPage";
import WebhookManagementFormPage from "../pages/WebhookManagement/WebhookManagementFormPage";

export const AppRoutes = [
  {
    url: "/",
    label: "Início",
    breadcrumb: [{ url: "/", label: "Início" }],
  },
  {
    url: "/irmao",
    label: "Irmão",
    breadcrumb: [{ url: "/irmao", label: "Irmão" }],
  },
  {
    url: "/irmao/novo",
    label: "Novo irmão",
    breadcrumb: [
      { url: "/irmao", label: "Irmão" },
      { label: "Novo Irmão", disabled: true },
    ],
  },
  {
    url: "/irmao/:id",
    label: "Editar irmão",
    breadcrumb: [
      { url: "/irmao", label: "Irmão" },
      { label: "Editar Irmão", disabled: true },
    ],
  },

  {
    url: "/cobranca",
    label: "Cobrança",
    breadcrumb: [{ url: "/cobranca", label: "Cobrança" }],
  },
  {
    url: "/cobranca/novo",
    label: "Nova cobrança",
    breadcrumb: [
      { url: "/cobranca", label: "Cobrança" },
      { label: "Nova Cobrança", disabled: true },
    ],
  },
  {
    url: "/cobranca/:id",
    label: "Editar cobrança",
    breadcrumb: [
      { url: "/cobranca", label: "Cobrança" },
      { label: "Editar Cobrança", disabled: true },
    ],
  },
  {
    url: "/controle/usuario",
    label: "Usuário",
    breadcrumb: [{ url: "/controle/usuario", label: "Usuário" }],
  },
  {
    url: "/controle/usuario/novo",
    label: "Novo usuário",
    breadcrumb: [
      { url: "/controle/usuario", label: "Usuário" },
      { label: "Novo Usuário", disabled: true },
    ],
  },
  {
    url: "/controle/usuario/:id",
    label: "Editar usuário",
    breadcrumb: [
      { url: "/controle/usuario", label: "Usuário" },
      { label: "Editar Usuário", disabled: true },
    ],
  },
  {
    url: "/escrutinio",
    label: "Escrutínio secreto",
    breadcrumb: [{ url: "/escrutinio", label: "Escrutínio Secreto" }],
  },
  {
    url: "/escrutinio/novo",
    label: "Novo escrutínio",
    breadcrumb: [
      { url: "/escrutinio", label: "Escrutínio Secreto" },
      { label: "Novo escrutínio", disabled: true },
    ],
  },
  {
    url: "/escrutinio/:id",
    label: "Detalhes do Escrutínio",
    breadcrumb: [
      { url: "/escrutinio", label: "Escrutínio Secreto" },
      { label: "Detalhes do Escrutínio", disabled: true },
    ],
  },
  {
    url: "/iniciacao",
    label: "Iniciação",
    breadcrumb: [{ url: "/iniciacao", label: "Iniciação" }],
  },
  {
    url: "/iniciacao/convite",
    label: "Convite de Iniciação",
    breadcrumb: [{ url: "/iniciacao/convite", label: "Convite de Iniciação" }],
  },
  {
    url: "/iniciacao/convite/novo",
    label: "Novo Convite de Iniciação",
    breadcrumb: [
      { url: "/iniciacao/convite", label: "Convite de Iniciação" },
      { label: "Novo Convite de Iniciação", disabled: true },
    ],
  },
  {
    url: "/profano",
    label: "Profano",
    breadcrumb: [{ url: "/profano", label: "Profano" }],
  },
  {
    url: "/profano/:id",
    label: "Detalhes do profano",
    breadcrumb: [
      { url: "/profano", label: "Profano" },
      { label: "Detalhes do profano", disabled: true },
    ],
  },
  {
    url: "/turma",
    label: "Turma",
    breadcrumb: [{ url: "/turma", label: "Turma" }],
  },
  {
    url: "/turma/novo",
    label: "Nova turma",
    breadcrumb: [
      { url: "/turma", label: "Turma" },
      { label: "Nova turma", disabled: true },
    ],
  },
  {
    url: "/turma/:id",
    label: "Detalhes da turma",
    breadcrumb: [
      { url: "/turma", label: "Turma" },
      { label: "Detalhes da turma", disabled: true },
    ],
  },
  {
    url: "/notificacao",
    label: "Notificação",
    breadcrumb: [{ url: "/notificacao", label: "Notifição" }],
  },
  {
    url: "/notificacao/novo",
    label: "Nova notificação",
    breadcrumb: [
      { url: "/notificao", label: "Notificação" },
      { label: "Nova notificação", disabled: true },
    ],
  },
  {
    url: "/notificacao/:id",
    label: "Detalhes da notificação",
    breadcrumb: [
      { url: "/turma", label: "Notificação" },
      { label: "Detalhes da notificação", disabled: true },
    ],
  },
  {
    url: "/resposta/sms",
    label: "Respostas sms",
    breadcrumb: [{ url: "/resposta/sms", label: "Respostas sms" }],
  },
  {
    url: "/filiacao/convite",
    label: "Ficha de Filiação",
    breadcrumb: [{ url: "/filiacao/convite", label: "Ficha de Filiação" }],
  },
  {
    url: "/filiando",
    label: "Filiando",
    breadcrumb: [{ url: "/filiando", label: "Filiando" }],
  },
  {
    url: "/filiando/:id",
    label: "Detalhes do filiando",
    breadcrumb: [
      { url: "/filiando", label: "Filiando" },
      { label: "Detalhes do filiando", disabled: true },
    ],
  },
  {
    url: "/pecas-arquitetura",
    label: "Peças de Arquitetura",
    breadcrumb: [{ url: "/pecas-arquitetura", label: "Peças de Arquitetura" }],
  },
  {
    url: "/pecas-arquitetura/novo",
    label: "Nova Peça de Arquitetura",
    breadcrumb: [
      { url: "/pecas-arquitetura", label: "Peças de Arquitetura" },
      { label: "Nova Peça de Arquitetura", disabled: true },
    ],
  },
  {
    url: "/pecas-arquitetura/:id",
    label: "Editar Peça de Arquitetura",
    breadcrumb: [
      { url: "/pecas-arquitetura", label: "Peças de Arquitetura" },
      { label: "Editar Peça de Arquitetura", disabled: true },
    ],
  },
  {
    url: "/publicacoes",
    label: "Publicações",
    breadcrumb: [{ url: "/publicacoes", label: "Publicações" }],
  },
  {
    url: "/publicacoes/novo",
    label: "Nova Publicação",
    breadcrumb: [
      { url: "/publicacoes", label: "Publicações" },
      { label: "Nova Publicação", disabled: true },
    ],
  },
  {
    url: "/publicacoes/:id",
    label: "Editar Publicação",
    breadcrumb: [
      { url: "/publicacoes", label: "Publicações" },
      { label: "Editar Publicação", disabled: true },
    ],
  },
  {
    url: "/confirmacao-participacao",
    label: "Confirmação de Participação",
    breadcrumb: [{ url: "/confirmacao-participacao", label: "Confirmação de Participação" }],
  },
  {
    url: "/acervo",
    label: "Acervo",
    breadcrumb: [{ url: "/acervo", label: "Acervo" }],
  },
  {
    url: "/acervo/novo",
    label: "Novo Livro",
    breadcrumb: [
      { url: "/acervo", label: "Acervo" },
      { label: "Novo Livro", disabled: true },
    ],
  },
  {
    url: "/acervo/:id",
    label: "Editar Livro",
    breadcrumb: [
      { url: "/acervo", label: "Acervo" },
      { label: "Editar Livro", disabled: true },
    ],
  },
  {
    url: "/acervo/:id/exemplares",
    label: "Exemplares",
    breadcrumb: [
      { url: "/acervo", label: "Acervo" },
      { label: "Exemplares", disabled: true },
    ],
  },
  {
    url: "/leitor",
    label: "Leitores",
    breadcrumb: [{ url: "/leitor", label: "Leitores" }],
  },
  {
    url: "/leitor/novo",
    label: "Novo Leitor",
    breadcrumb: [
      { url: "/leitor", label: "Leitores" },
      { label: "Novo Leitor", disabled: true },
    ],
  },
  {
    url: "/leitor/:id",
    label: "Editar Leitor",
    breadcrumb: [
      { url: "/leitor", label: "Leitores" },
      { label: "Editar Leitor", disabled: true },
    ],
  },
  {
    url: "/nominata",
    label: "Nominatas Administrativas",
    breadcrumb: [{ url: "/nominata", label: "Nominatas Administrativas" }],
  },
  {
    url: "/nominata/novo",
    label: "Nova Nominata",
    breadcrumb: [
      { url: "/nominata", label: "Nominatas Administrativas" },
      { label: "Nova Nominata", disabled: true },
    ],
  },
  {
    url: "/nominata/:id",
    label: "Editar Nominata",
    breadcrumb: [
      { url: "/nominata", label: "Nominatas Administrativas" },
      { label: "Editar Nominata", disabled: true },
    ],
  },
  {
    url: "/emprestimo",
    label: "Empréstimos",
    breadcrumb: [{ url: "/leitor", label: "Empréstimos" }],
  },
  {
    url: "/datas-comemorativas",
    label: "Datas Comemorativas",
    breadcrumb: [{ url: "/datas-comemorativas", label: "Datas Comemorativas" }],
  },
  {
    url: "/datas-comemorativas/novo",
    label: "Nova Data Comemorativa",
    breadcrumb: [
      { url: "/datas-comemorativas", label: "Datas Comemorativas" },
      { label: "Nova Data Comemorativa", disabled: true },
    ],
  },
  {
    url: "/datas-comemorativas/:id",
    label: "Editar Data Comemorativa",
    breadcrumb: [
      { url: "/datas-comemorativas", label: "Datas Comemorativas" },
      { label: "Editar Data Comemorativa", disabled: true },
    ],
  },
  {
    url: "/podcast",
    label: "Podcasts",
    breadcrumb: [{ url: "/podcast", label: "Podcasts" }],
  },
  {
    url: "/podcast/novo",
    label: "Novo Podcast",
    breadcrumb: [
      { url: "/podcast", label: "Podcasts" },
      { label: "Novo Podcast", disabled: true },
    ],
  },
  {
    url: "/podcast/:id",
    label: "Editar Podcast",
    breadcrumb: [
      { url: "/podcast", label: "Podcasts" },
      { label: "Editar Podcast", disabled: true },
    ],
  },
  {
    url: "/podcast/:id/temporada",
    label: "Temporadas",
    breadcrumb: [
      { url: "/podcast", label: "Podcasts" },
      { label: "Temporadas", disabled: true },
    ],
  },
  {
    url: "/podcast/:id/temporada/novo",
    label: "Nova Temporada",
    breadcrumb: [
      { url: "/podcast", label: "Podcasts" },
      { url: "/podcast/:id/temporada", label: "Temporadas", disabled: true },
      { label: "Nova Temporada", disabled: true },
    ],
  },
  {
    url: "/podcast/:id/temporada/:temporadaId",
    label: "Editar Temporada",
    breadcrumb: [
      { url: "/podcast", label: "Podcasts" },
      { url: "/podcast/:id/temporada", label: "Temporadas", disabled: true },
      { label: "Editar Temporada", disabled: true },
    ],
  },
  {
    url: "/podcast/:id/temporada/:temporadaId/episodio",
    label: "Episódios",
    breadcrumb: [
      { url: "/podcast", label: "Podcasts" },
      { url: "/podcast/:id/temporada", label: "Temporadas", disabled: true },
      { label: "Episódios", disabled: true },
    ],
  },
  {
    url: "/podcast/:id/temporada/:temporadaId/episodio/novo",
    label: "Novo Episódio",
    breadcrumb: [
      { url: "/podcast", label: "Podcasts" },
      { url: "/podcast/:id/temporada", label: "Temporadas", disabled: true },
      { label: "Episódios", disabled: true },
      { label: "Novo Episódio", disabled: true },
    ],
  },
  {
    url: "/podcast/:id/temporada/:temporadaId/episodio/:episodioId",
    label: "Editar Episódio",
    breadcrumb: [
      { url: "/podcast", label: "Podcasts" },
      { url: "/podcast/:id/temporada", label: "Temporadas", disabled: true },
      { label: "Episódios", disabled: true },
      { label: "Editar Episódio", disabled: true },
    ],
  },
  {
    url: "/assinatura",
    label: "Assinatura",
    breadcrumb: [{ url: "/assinatura", label: "Assinatura" }],
  },
  {
    url: "/mobile-version",
    label: "Versões do aplicativo",
    breadcrumb: [{ url: "/mobile-version", label: "Versões do aplicativo" }],
  },
  {
    url: "/mobile-version/novo",
    label: "Nova versão",
    breadcrumb: [
      { url: "/mobile-version", label: "Versões do aplicativo" },
      { label: "Nova versão", disabled: true },
    ],
  },
  {
    url: "/mobile-version/:id",
    label: "Editar versão",
    breadcrumb: [
      { url: "/mobile-version", label: "Versões do aplicativo" },
      { label: "Editar versão", disabled: true },
    ],
  },
  {
    url: "/lodgeWebhookManagement",
    label: "Webhooks",
    breadcrumb: [{ url: "/lodgeWebhookManagement", label: "Webhooks" }],
  },
  {
    url: "/lodgeWebhookManagement/novo",
    label: "Novo Webhook",
    breadcrumb: [
      { url: "/lodgeWebhookManagement", label: "Webhooks" },
      { label: "Novo Webhook", disabled: true },
    ],
  },
  {
    url: "/lodgeWebhookManagement/:id",
    label: "Editar Webhook",
    breadcrumb: [
      { url: "/lodgeWebhookManagement", label: "Webhooks" },
      { label: "Editar Webhook", disabled: true },
    ],
  },
  {
    url: "*",
    label: "Erro 404",
    component: Page404,
    breadcrumb: [{ label: "Erro 404" }],
  },
];

const Routes: React.FC = () => {
  const { showValueInDashboard } = useAuth();
  if (
    useRouteMatch([
      "/pdf/**",
      "/convite/**",
      "/proposta-filiacao/**",
      "/votacao/**",
      "/filiandos/detalhes/**",
      "/profanos/detalhes/**",
      "/glomam",
      "/glomam/**",
      "/excluir-conta",
    ]) !== null
  ) {
    return (
      <Switch>
        <NativeRouter path="/pdf/certificado/:uuid" exact component={IrmaoPage} />
        <NativeRouter path="/convite/:token" exact component={ProfanoSignInPage} />
        <NativeRouter path="/convite/:token/form" exact component={FormProfanoPage} />
        <NativeRouter path="/proposta-filiacao/:token" exact component={ProfanoSignInPage} />
        <NativeRouter path="/proposta-filiacao/:token/form" exact component={FormProfanoPage} />
        <NativeRouter path="/votacao/escrutinio/:token" exact component={VotacaoEscrutinioPage} />
        <NativeRouter path="/pdf/peca/:uuid" exact component={FilePagePecasArquitetura} />
        <NativeRouter path="/pdf/fichaFiliando/:uuid" exact component={FilePageFichaFiliando} />
        <NativeRouter path="/pdf/fichaIniciacao/:uuid" exact component={FilePageInformacoesProfano} />
        <NativeRouter path="/pdf/fichaSindicancia/:uuid" exact component={FilePageFichaSindicancia} />
        <NativeRouter
          path="/pdf/fichaPreenchidaSindicancia/:uuid"
          exact
          component={FilePageFichaSindicanciaPreenchida}
        />
        <NativeRouter path="/pdf/turmaProfano/:uuid" exact component={FilePageTurmaProfano} />
        <NativeRouter path="/pdf/testamento/:uuid" exact component={FilePageTestamento} />
        <NativeRouter path="/pdf/anexo/:uuid" exact component={FilePageAnexo} />
        <NativeRouter path="/pdf/declaracaoVontade/:uuid" exact component={FilePageDeclaracaoVontade} />
        <NativeRouter path="/filiandos/detalhes/:uuid" exact component={FilePageDetalhesFiliandos} />
        <NativeRouter path="/profanos/detalhes/:uuid" exact component={FilePageDetalhesProfanos} />
        <NativeRouter path="/filiandos/detalhes/:uuid/anexosMerge" exact component={FilePageDetalhesFiliandosMerge} />
        <NativeRouter path="/profanos/detalhes/:uuid/anexosMerge" exact component={FilePageDetalhesProfanoMerge} />

        {/* <NativeRouter
          path="/filiandos/detalhes/:uuid/sindicanciasMerge"
          exact
          component={FilePageDetalhesFiliandosMerge}
        /> */}
        <NativeRouter
          path="/profanos/detalhes/:uuid/sindicanciasMerge"
          exact
          component={FilePageDetalhesProfanoSindicanciasMerge}
        />

        <NativeRouter path="/pdf/publicacao/:uuid" exact component={FilePagePecasArquitetura} />
        <NativeRouter path="/pdf/recibo/:uuid" exact component={FilePageRecibo} />

        <NativeRouter path="/glomam" exact component={ConviteGlomam} />
        <NativeRouter path="/glomam/:uuid" exact component={ConviteGlomam} />
        <NativeRouter path="/excluir-conta" exact component={ExcluirContaPage} />
      </Switch>
    );
  }
  return (
    <MainContainer>
      <Switch>
        {showValueInDashboard ? (
          <Route path="/" exact component={Dashboard} isPrivate />
        ) : (
          <Route path="/" exact component={DashboardWithOutIrmao} isPrivate />
        )}
        <Route path="/assinatura/:token" exact component={SignDocumentPage} />

        <Route path="/login" exact component={SignIn} />
        <Route path="/irmao" exact component={IrmaoPage} isPrivate />
        <Route path="/irmao/:id" exact component={IrmaoFormPage} isPrivate />
        <Route path="/irmao/novo" exact component={IrmaoFormPage} isPrivate />
        <Route path="/cobranca" exact component={CobrancaPage} isPrivate />
        <Route path="/cobranca/:id" exact component={CobrancaFormPage} isPrivate />
        <Route path="/cobranca/novo" exact component={CobrancaFormPage} isPrivate />
        <Route path="/controle/usuario" exact component={UsuarioPage} isPrivate />
        <Route path="/controle/usuario/:id" exact component={UsuarioFormPage} isPrivate />
        <Route path="/controle/usuario/novo" exact component={UsuarioFormPage} isPrivate />
        <Route path="/iniciacao/convite" exact component={CrudProfanoPage} isPrivate />
        <Route path="/iniciacao/convite/novo" exact component={IniciacaoProfanoFormPage} isPrivate />
        <Route path="/iniciacao" exact component={CrudProfanoPage} isPrivate />
        <Route path="/iniciacao/novo" exact component={IniciacaoProfanoFormPage} isPrivate />
        <Route path="/iniciacao-profano/:token" exact component={ProfanoSignInPage} />
        <Route path="/iniciacao-profano/:token/form" exact component={FormProfanoPage} />
        <Route path="/escrutinio" exact component={EscrutinioPage} isPrivate />
        <Route path="/escrutinio/novo" exact component={EscrutinioFormPage} isPrivate />
        <Route path="/escrutinio/:id" exact component={EscrutinioDetails} isPrivate />
        <Route path="/filiacao/convite" exact component={ConviteFiliacaoPage} isPrivate />
        <Route path="/filiacao-irmao/:token" exact component={ProfanoSignInPage} />
        <Route path="/filiacao-irmao/:token/form" exact component={FormProfanoPage} />
        <Route path="/profano" exact component={ProfanoPage} isPrivate />
        <Route path="/profano/:id" exact component={ProfanoFormPage} isPrivate />
        <Route path="/filiando" exact component={FiliandoPage} isPrivate />
        <Route path="/filiando/:id" exact component={FiliandoFormPage} isPrivate />
        <Route path="/turma" exact component={TurmaPage} isPrivate />
        <Route path="/turma/:id" exact component={TurmaFormPage} isPrivate />
        <Route path="/notificacao" exact component={NotificaoPage} isPrivate />
        <Route path="/notificacao/:id" exact component={NotificacaoFormPage} isPrivate />
        <Route path="/resposta/sms" exact component={RespostaSMSPage} isPrivate />
        <Route path="/pecas-arquitetura" exact component={PecasArquiteturaPage} isPrivate />
        <Route path="/pecas-arquitetura/novo" exact component={PecasArquiteturaFormPage} isPrivate />
        <Route path="/pecas-arquitetura/:id" exact component={PecasArquiteturaFormPage} isPrivate />
        <Route path="/publicacoes" exact component={PublicacaoPage} isPrivate />
        <Route path="/publicacoes/novo" exact component={PublicacaoFormPage} isPrivate />
        <Route path="/publicacoes/:id" exact component={PublicacaoFormPage} isPrivate />
        <Route path="/confirmacao-participacao" exact component={ConfirmacaoParticipacaoPage} isPrivate />
        <Route path="/acervo" exact component={AcervoPage} isPrivate />
        <Route path="/acervo/novo" exact component={AcervoFormPage} isPrivate />
        <Route path="/acervo/:id" exact component={AcervoFormPage} isPrivate />
        <Route path="/acervo/:id/exemplares" exact component={ExemplaresFormPage} isPrivate />
        <Route path="/leitor" exact component={LeitoresPage} isPrivate />
        <Route path="/leitor/novo" exact component={LeitoresFormPage} isPrivate />
        <Route path="/leitor/:id" exact component={LeitoresFormPage} isPrivate />
        <Route path="/nominata" exact component={NominataPage} isPrivate />
        <Route path="/nominata/novo" exact component={NominataFormPage} isPrivate />
        <Route path="/nominata/:id" exact component={NominataFormPage} isPrivate />
        <Route path="/emprestimo" exact component={EmprestimoPage} isPrivate />
        <Route path="/datas-comemorativas" exact component={DatasComemorativasPage} isPrivate />
        <Route path="/datas-comemorativas/novo" exact component={DatasComemorativasFormPage} isPrivate />
        <Route path="/datas-comemorativas/:id" exact component={DatasComemorativasFormPage} isPrivate />
        <Route path="/podcast" exact component={PodcastPage} isPrivate />
        <Route path="/podcast/novo" exact component={PodcastFormPage} isPrivate />
        <Route path="/podcast/:id" exact component={PodcastFormPage} isPrivate />
        <Route path="/podcast/:id/temporada" exact component={TemporadaPage} isPrivate />
        <Route path="/podcast/:id/temporada/novo" exact component={TemporadaFormPage} isPrivate />
        <Route path="/podcast/:id/temporada/:temporadaId" exact component={TemporadaFormPage} isPrivate />
        <Route path="/podcast/:id/temporada/:temporadaId" exact component={TemporadaFormPage} isPrivate />
        <Route path="/podcast/:id/temporada/:temporadaId/episodio" exact component={EpisodioPage} isPrivate />
        <Route path="/podcast/:id/temporada/:temporadaId/episodio/novo" exact component={EpisodioFormPage} isPrivate />
        <Route
          path="/podcast/:id/temporada/:temporadaId/episodio/:episodioId"
          exact
          component={EpisodioFormPage}
          isPrivate
        />
        <Route path="/mobile-version" exact component={MobileVersionPage} isPrivate />
        <Route path="/mobile-version/novo" exact component={MobileVersionForm} isPrivate />
        <Route path="/mobile-version/:id" exact component={MobileVersionForm} isPrivate />
        <Route path="/lodgeWebhookManagement" exact component={WebhookManagementPage} isPrivate />
        {/* <Route path="/lodgeWebhookManagement/novo" exact component={WebhookManagementFormPage} isPrivate /> */}
        <Route path="/lodgeWebhookManagement/:id" exact component={WebhookManagementFormPage} isPrivate />
        <Route path="*" component={Page404} isPrivate />
      </Switch>
    </MainContainer>
  );
};

export default Routes;
