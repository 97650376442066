/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { useParams, useHistory } from "react-router-dom";
import _ from "lodash";

import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import { TabPanel, TabView } from "primereact/tabview";
import Loading from "../../../components/Loading";
import FloatingSave from "../../../components/FloatingSave";
import { cobrancaErrors } from "../../../errors/cobranca";
import SimpleEntityListPage from "../../../components/SimpleEntityPage";
import CustomFormField from "../../../components/CustomFormField";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import FormHeader from "../../../components/FormHeader";
import { convertDateToStringBR, removeOffsetFromDate } from "../../../utils/convertDates";
import SituacaoCobranca, { SituacaoCobrancaTipo, situacoesCobrancaMap } from "../../../components/SituacaoCobranca";
import { formatDateToStringForSend } from "../../../utils/formatDateToStringForSend";
import { useError } from "../../../hooks/error";
import { FormaPagamento, FormaPagamentoElement, formaPagamentoEParcelasString, formaPagamentoMap } from "../utils";

interface Params {
  id: string;
}

export interface AsaasErrorResponse {
  errors?: AsaasError[];
}

export interface AsaasError {
  code: string;
  description: string;
}

interface IrmaoDropdown {
  id?: number;
  uuid: string;
  nome: string;
}

interface CobrancaForm {
  uuid: string;
  valor: number;
  dtVencimento: string;
  dtPagamento?: string;
  dtPagamentoAprovado?: string;
  irmao: any;
  tipo: TipoCobranca;
  formaPagamento?: FormaPagamento;
  valorRecebido?: number;

  situacao: SituacaoCobrancaTipo;
  visualizado?: boolean;
  dtGeracao?: string;
  responsavelGeracao?: string;

  descricao?: string;
  responsavelPagamento?: string;
  situacaoErro?: AsaasErrorResponse;
  urlPagamento?: string;
  parcelas?: number;

  // campos novos
  quebraEmMensalidades: boolean;
  qtdMensalidades?: number;
  tipoDesconto: TipoDesconto;
  percentualDesconto?: number;
  valorDesconto: number;
  prazoDesconto: number;
  formaPagamentoAceita: FormaPagamento;
  valorExtraPixBoleto: number;
  valorExtraCartaoCredito: number;
  maximoParcelas: number;
}

export type TipoDesconto = "PERCENTUAL" | "VALOR_FIXO";

export interface TipoDescontoElement {
  label: string;
  tipo: TipoDesconto;
}
type DescontoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in TipoDesconto]: TipoDescontoElement;
};

export const tiposDescontoMap: DescontoMap = {
  PERCENTUAL: {
    label: "Percentual",
    tipo: "PERCENTUAL",
  },
  VALOR_FIXO: { label: "Valor fixo", tipo: "VALOR_FIXO" },
};

export type TipoCobranca =
  | "MENSALIDADE"
  | "ANUIDADE"
  | "COTA"
  | "JOIA"
  | "GRANDE_TRONCO"
  | "ANUIDADE_GLOMAM"
  | "MENSALIDADE_GLOMAM";

export interface TipoCobrancaElement {
  label: string;
  tipo: TipoCobranca;
}
type SituacaoMap = {
  // eslint-disable-next-line no-unused-vars
  [name in TipoCobranca]: TipoCobrancaElement;
};

export const tiposCobrancaMap: SituacaoMap = {
  MENSALIDADE: {
    label: "Mensalidade / parcela",
    tipo: "MENSALIDADE",
  },
  ANUIDADE: { label: "Anuidade Loja", tipo: "ANUIDADE" },
  COTA: {
    label: "Cota",
    tipo: "COTA",
  },
  JOIA: {
    label: "Jóia",
    tipo: "JOIA",
  },
  GRANDE_TRONCO: {
    label: "Grande Tronco",
    tipo: "GRANDE_TRONCO",
  },
  MENSALIDADE_GLOMAM: {
    label: "Mensalidade GLOMAM",
    tipo: "MENSALIDADE_GLOMAM",
  },
  ANUIDADE_GLOMAM: {
    label: "Anuidade GLOMAM",
    tipo: "ANUIDADE_GLOMAM",
  },
};

const prazosDesconto = [
  "Até o dia do vencimento",
  "Até 1 dia antes do vencimento",
  "Até 2 dias antes do vencimento",
  "Até 3 dias antes do vencimento",
  "Até 4 dias antes do vencimento",
  "Até 5 dias antes do vencimento",
  "Até 6 dias antes do vencimento",
  "Até 7 dias antes do vencimento",
  "Até 8 dias antes do vencimento",
  "Até 9 dias antes do vencimento",
  "Até 10 dias antes do vencimento",
];

const qtdsMensalidades = [
  "2 mensalidades / parcelas",
  "3 mensalidades / parcelas",
  "4 mensalidades / parcelas",
  "5 mensalidades / parcelas",
  "6 mensalidades / parcelas",
  "7 mensalidades / parcelas",
  "8 mensalidades / parcelas",
  "9 mensalidades / parcelas",
  "10 mensalidades / parcelas",
  "11 mensalidades / parcelas",
  "12 mensalidades / parcelas",
];

const maximosParcelas = [
  "À vista",
  "Até 2x no cartão",
  "Até 3x no cartão",
  "Até 4x no cartão",
  "Até 5x no cartão",
  "Até 6x no cartão",
  "Até 7x no cartão",
  "Até 8x no cartão",
  "Até 9x no cartão",
  "Até 10x no cartão",
  "Até 11x no cartão",
  "Até 12x no cartão",
];

const CobrancaFormPage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingDropdown, setLoadingDropdown] = useState(false);
  const [loadingPagar, setLoadingPagar] = useState(false);
  const [loadingProcessar, setLoadingProcessar] = useState(false);
  const [loadingCancelar, setLoadingCancelar] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);
  const [loadingGerarUrl, setLoadingGerarUrl] = useState(false);
  const [loadingAnistiar, setLoadingAnistiar] = useState(false);
  const [displayConfirmPagamentoDialog, setDisplayConfirmPagamentoDialog] = useState(false);
  const [cobrancaInicial, setCobrancaInicial] = useState<CobrancaForm>({} as CobrancaForm);

  const [urlPagamento, setUrlPagamento] = useState<string>("");
  const [urlRecibo, setUrlRecibo] = useState<string>("");
  const [valor, setValor] = useState<number | undefined | null>(undefined);
  const [descricao, setDescricao] = useState<string | undefined>("");
  const [dataVencimento, setDataVencimento] = useState<Date | Date[] | undefined>(undefined);
  const [tipo, setTipo] = useState<TipoCobrancaElement>(tiposCobrancaMap.ANUIDADE);
  const [formaPagamento, setFormaPagamento] = useState<FormaPagamentoElement>();
  const [parcelas, setParcelas] = useState<number>();
  const [irmao, setIrmao] = useState<IrmaoDropdown[]>([]);
  const [dataFormatada, setDataFormatada] = useState("");
  const [situacao, setSituacao] = useState<SituacaoCobrancaTipo | undefined>(undefined);
  const [visualizado, setVisualizado] = useState<boolean | undefined>(undefined);
  const [situacaoErro, setSituacaoErro] = useState<AsaasErrorResponse | undefined>(undefined);

  const [qtdMensalidades, setQtdMensalidades] = useState<string>(qtdsMensalidades[0]);
  const [maximoParcelas, setMaximoParcelas] = useState<string>(maximosParcelas[0]);
  const [quebraEmMensalidades, setQuebraEmMensalidades] = useState<boolean>(false);
  const [tipoDesconto, setTipoDesconto] = useState<TipoDescontoElement>(tiposDescontoMap.PERCENTUAL);
  const [percentualDesconto, setPercentualDesconto] = useState<number>(0);
  const [valorDesconto, setValorDesconto] = useState<number>(0);
  const [prazoDesconto, setPrazoDesconto] = useState<string>(prazosDesconto[0]);
  const [formaPagamentoAceita, setFormaPagamentoAceita] = useState<FormaPagamentoElement>(formaPagamentoMap.UNDEFINED);
  const [valorExtraPixBoleto, setValorExtraPixBoleto] = useState<number>(0);
  const [valorExtraCartaoCredito, setValorExtraCartaoCredito] = useState<number>(0);

  const [isDirty, setIsDirty] = useState(false);

  const tipoOptions = useMemo(
    (): TipoCobrancaElement[] => [
      tiposCobrancaMap.MENSALIDADE,
      tiposCobrancaMap.MENSALIDADE_GLOMAM,
      tiposCobrancaMap.ANUIDADE,
      tiposCobrancaMap.ANUIDADE_GLOMAM,
      tiposCobrancaMap.JOIA,
      tiposCobrancaMap.GRANDE_TRONCO,
      tiposCobrancaMap.COTA,
    ],
    []
  );

  const [irmaoOptions, setIrmaoOptions] = useState<IrmaoDropdown[]>([]);

  const [dataPagamento, setDataPagamento] = useState<Date | undefined>();
  const [valorRecebido, setValorRecebido] = useState<number | null>(null);
  const [valorRecebidoExibir, setValorRecebidoExibir] = useState<number>();
  const [dataPagamentoAprovado, setDataPagamentoAprovado] = useState<Date | undefined>();

  const { showToast } = useToast();
  const { handleError } = useError();

  const [errors, setErrors] = useState<{ [campo: string]: string }>({});

  const { id } = useParams<Params>();
  const history = useHistory();

  const onShowConfirmPagamentoDialog = () => {
    setDisplayConfirmPagamentoDialog(true);
  };

  const onHideConfirmPagamentoDialog = () => {
    setDisplayConfirmPagamentoDialog(false);
  };

  const loadCobranca = useCallback(() => {
    setLoading(true);
    api
      .get<CobrancaForm>(`cobrancas/${id}`)
      .then(({ data }) => {
        setCobrancaInicial({
          uuid: data.uuid,
          valor: data.valor ? data.valor : 0,
          descricao: data.descricao ? data.descricao : "",
          dtVencimento: data.dtVencimento,
          irmao: [data.irmao],
          tipo: data.tipo,
          situacao: data.situacao,
          responsavelPagamento: data.responsavelPagamento,
          dtPagamento: data.dtPagamento,
          valorRecebido: data.valorRecebido,
          // campos novos
          quebraEmMensalidades: data.quebraEmMensalidades,
          qtdMensalidades: data.qtdMensalidades,
          tipoDesconto: data.tipoDesconto,
          percentualDesconto: data.percentualDesconto,
          valorDesconto: data.valorDesconto,
          prazoDesconto: data.prazoDesconto,
          formaPagamentoAceita: data.formaPagamentoAceita,
          valorExtraPixBoleto: data.valorExtraPixBoleto,
          valorExtraCartaoCredito: data.valorExtraCartaoCredito,
          maximoParcelas: data.maximoParcelas,
        });
        // setUrlPagamento(data.urlPagamento || "");

        if (
          situacoesCobrancaMap[data.situacao] === situacoesCobrancaMap.APROVADO ||
          situacoesCobrancaMap[data.situacao] === situacoesCobrancaMap.PAGO
        )
          setUrlRecibo(`${window.location.origin}/pdf/recibo/${data.uuid}`);

        setUrlPagamento(data.urlPagamento || "");
        setValor(data.valor);
        setDescricao(data.descricao ? data.descricao : "");

        setDataFormatada(formatDateToStringForSend(data.dtVencimento) || "");

        setDataVencimento(removeOffsetFromDate(new Date(data.dtVencimento)));

        if (data.dtPagamento) {
          setDataPagamento(removeOffsetFromDate(new Date(data.dtPagamento)));
        }
        if (data.dtPagamentoAprovado) {
          setDataPagamentoAprovado(removeOffsetFromDate(new Date(data.dtPagamentoAprovado)));
        }
        setIrmao([data.irmao]);
        setTipo(tiposCobrancaMap[data.tipo]);
        if (data.formaPagamento) {
          setFormaPagamento(formaPagamentoMap[data.formaPagamento]);
        }

        setParcelas(data.parcelas);

        setSituacao(data.situacao);
        setVisualizado(data.visualizado);
        if (data.situacaoErro) {
          setSituacaoErro(data.situacaoErro);
        }

        setValorRecebidoExibir(data.valorRecebido);

        setQuebraEmMensalidades(data.quebraEmMensalidades);
        if (data.qtdMensalidades) {
          const qtdMensalidadesIndex = data.qtdMensalidades - 2;
          if (data.qtdMensalidades >= 2 && qtdMensalidadesIndex < qtdsMensalidades.length) {
            setQtdMensalidades(qtdsMensalidades[qtdMensalidadesIndex]);
          }
        }

        setTipoDesconto(tiposDescontoMap[data.tipoDesconto]);
        setPercentualDesconto(data.percentualDesconto || 0);
        setValorDesconto(data.valorDesconto);
        if (data.prazoDesconto >= 0 && data.prazoDesconto < prazosDesconto.length) {
          setPrazoDesconto(prazosDesconto[data.prazoDesconto]);
        }

        setFormaPagamentoAceita(formaPagamentoMap[data.formaPagamentoAceita]);
        setValorExtraPixBoleto(data.valorExtraPixBoleto);
        setValorExtraCartaoCredito(data.valorExtraCartaoCredito);
        const maximoParcelasIndex = data.maximoParcelas - 1;
        if (data.maximoParcelas > 0 && maximoParcelasIndex < maximosParcelas.length) {
          setMaximoParcelas(maximosParcelas[maximoParcelasIndex]);
        }

        // TODO: adicionar novos SETs para os campos novos, conforme o findOne
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar a cobrança.",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, showToast]);

  const redefinirCobranca = useCallback(() => {
    setIsDirty(false);
    if (id === "novo") {
      setErrors({});
      setValor(null);
      setDataVencimento([]);
      setDataFormatada("");
      setDescricao("");
      setTipo(tiposCobrancaMap.ANUIDADE);
      setIrmao([]);
      setQuebraEmMensalidades(false);
      setQtdMensalidades(qtdsMensalidades[0]);
      setTipoDesconto(tiposDescontoMap.PERCENTUAL);
      setPercentualDesconto(0);
      setValorDesconto(0);
      setPrazoDesconto(prazosDesconto[0]);
      setFormaPagamentoAceita(formaPagamentoMap.UNDEFINED);
      setValorExtraPixBoleto(0);
      setValorExtraCartaoCredito(0);
      setMaximoParcelas(maximosParcelas[0]);
    } else {
      loadCobranca();
    }
  }, [id, loadCobranca]);

  // TODO: incluir validação para os campos novos adicionados
  const validarCampos = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    if (valor === 0) {
      errorsLocal.valor = "Este é um campo obrigatório";
    }
    if (valor && valor < 5) {
      errorsLocal.valor = "Valor deve ser maior que R$ 5,00";
    }
    if (_.isEmpty(dataFormatada) || !dataFormatada) {
      errorsLocal.dataVencimento = "Este é um campo obrigatório";
    }

    const dtVencimento: Date = removeOffsetFromDate(new Date(dataFormatada));
    const today: Date = new Date();
    today.setHours(0, 0, 0, 0);
    if (dtVencimento.getTime() - today.getTime() < 0) {
      errorsLocal.dataVencimento = "Data de vencimento não pode estar no passado";
    }

    if (_.isEmpty(irmao)) {
      errorsLocal.irmao = "Este é um campo obrigatório";
    }
    if (_.isEmpty(tipo)) {
      errorsLocal.tipo = "Este é um campo obrigatório";
    }

    if (valor && valorDesconto >= valor) {
      errorsLocal.valorDesconto = `O desconto deve ser menor que o valor da cobrança`;
    }

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [dataFormatada, irmao, tipo, valor, valorDesconto]);

  const validacao = useCallback(() => {
    return (
      !!valor &&
      valor > 0 &&
      !_.isEmpty(dataFormatada) &&
      !_.isEmpty(irmao) &&
      !_.isEmpty(tipo) &&
      (!_.isEqual(cobrancaInicial.valor, valor) ||
        !_.isEqual(cobrancaInicial.descricao, descricao) ||
        !_.isEqual(cobrancaInicial.dtVencimento, dataFormatada) ||
        !_.isEqual(cobrancaInicial.irmao, irmao) ||
        !_.isEqual(cobrancaInicial.tipo, tipo))
    );
  }, [cobrancaInicial, dataFormatada, descricao, irmao, tipo, valor]);

  const [qtdCobrancaByIrmao, setQtdCobrancaByIrmao] = useState<number>(1);
  const [recurrenceModalVisible, setRecurrenceModalVisible] = useState(false);

  const showRecurrenceModal = useCallback(() => {
    if (validarCampos()) setRecurrenceModalVisible(true);
  }, [validarCampos]);

  const hideRecurrenceModal = useCallback(() => {
    setRecurrenceModalVisible(false);
  }, []);

  // TODO: adicionar campos novos ao salvar uma cobrança ( PUT)
  const salvarCobranca = useCallback(() => {
    const valid = validarCampos();
    if (valid) {
      const qtdMensalidadesTmp = qtdsMensalidades.findIndex((a) => a === qtdMensalidades) + 2;
      const prazoDescontoTmp = prazosDesconto.findIndex((a) => a === prazoDesconto);
      const maximoParcelasTmp = maximosParcelas.findIndex((a) => a === maximoParcelas) + 1;
      if (id === "novo") {
        setLoadingSalvar(true);

        const cobranca = {
          valor,
          descricao,
          dtVencimento: dataFormatada,
          idIrmaos: irmao.map((irmaos) => irmaos.id),
          tipo: tipo.tipo,
          quebraEmMensalidades,
          qtdMensalidades: qtdMensalidadesTmp >= 0 ? qtdMensalidadesTmp : 2,
          tipoDesconto: tipoDesconto.tipo,
          percentualDesconto: tipoDesconto.tipo === "PERCENTUAL" ? percentualDesconto : 0.0,
          valorDesconto,
          prazoDesconto: prazoDescontoTmp >= 0 ? prazoDescontoTmp : 0,
          formaPagamentoAceita: formaPagamentoAceita.value,
          valorExtraPixBoleto,
          valorExtraCartaoCredito,
          maximoParcelas: maximoParcelasTmp >= 0 ? maximoParcelasTmp : 1,
          qtdCobrancaByIrmao,
        };
        api
          .post("cobrancas", cobranca)
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "A cobrança foi criada." });
            history.push("/cobranca");
          })
          .catch((err) => {
            handleError({ error: err, action: "criar cobrança", knownErrors: cobrancaErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        setLoadingSalvar(true);
        const cobranca = {
          valor,
          descricao,
          dtVencimento: dataFormatada,
          tipo: tipo.tipo,
          quebraEmMensalidades,
          qtdMensalidades: qtdMensalidadesTmp >= 0 ? qtdMensalidadesTmp : 2,
          tipoDesconto: tipoDesconto.tipo,
          percentualDesconto: tipoDesconto.tipo === "PERCENTUAL" ? percentualDesconto : 0.0,
          valorDesconto,
          prazoDesconto: prazoDescontoTmp >= 0 ? prazoDescontoTmp : 0,
          formaPagamentoAceita: formaPagamentoAceita.value,
          valorExtraPixBoleto:
            formaPagamentoAceita.value === "BOLETO" || formaPagamentoAceita.value === "UNDEFINED"
              ? valorExtraPixBoleto
              : 0,
          valorExtraCartaoCredito:
            formaPagamentoAceita.value === "CREDIT_CARD" || formaPagamentoAceita.value === "UNDEFINED"
              ? valorExtraCartaoCredito
              : 0,
          maximoParcelas: maximoParcelasTmp >= 0 ? maximoParcelasTmp : 1,
        };
        api
          .put(`cobrancas/${id}`, cobranca)
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "A cobrança foi editada e salva." });
            history.push("/cobranca");
          })
          .catch((err) => {
            handleError({ error: err, action: "atualizar cobrança", knownErrors: cobrancaErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [
    dataFormatada,
    descricao,
    formaPagamentoAceita.value,
    handleError,
    history,
    id,
    irmao,
    maximoParcelas,
    percentualDesconto,
    prazoDesconto,
    qtdCobrancaByIrmao,
    qtdMensalidades,
    quebraEmMensalidades,
    showToast,
    tipo.tipo,
    tipoDesconto.tipo,
    validarCampos,
    valor,
    valorDesconto,
    valorExtraCartaoCredito,
    valorExtraPixBoleto,
  ]);

  const loadDropdown = useCallback(() => {
    api
      .get("irmaos/dropdown")
      .then((res) => {
        setIrmaoOptions(res.data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os irmãos",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => setLoadingDropdown(false));
  }, [showToast]);

  useEffect(() => {
    if (tipoDesconto.tipo === "PERCENTUAL" && valor) {
      setValorDesconto((valor * percentualDesconto) / 100);
    }
    if (valor === null || valor === undefined) {
      setValorDesconto(0);
    }
  }, [tipoDesconto, valor, percentualDesconto]);

  useEffect(() => {
    setLoadingDropdown(true);
    loadDropdown();
    if (id !== "novo") loadCobranca();
  }, [id, loadCobranca, loadDropdown]);

  const loadDropdownIrmaosRemidos = useCallback(() => {
    api
      .get("irmaos/dropdown/remido")
      .then((res) => {
        setIrmaoOptions(res.data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os irmãos",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => setLoadingDropdown(false));
  }, [showToast]);

  const loadDropdownIrmaosCota = useCallback(() => {
    api
      .get("irmaos/dropdown/cota")
      .then((res) => {
        setIrmaoOptions(res.data);
      })
      .catch(() => {
        showToast({
          type: "error",
          title: "Não foi possível carregar os irmãos",
          description: "Tente novamente ou contate nosso time de suporte para solicitar ajuda.",
        });
      })
      .finally(() => setLoadingDropdown(false));
  }, [showToast]);

  const yearNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="p-ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const monthNavigatorTemplate = useCallback((e: any) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  }, []);

  const efetuarPagamento = useCallback(() => {
    setLoadingPagar(true);
    const dataPagamentoEnviar = dataPagamento?.toISOString().split("T")[0];
    api
      .put(`cobrancas/${id}/pagamento`, { dtPagamento: dataPagamentoEnviar, valorRecebido })
      .then(() => {
        loadCobranca();
        showToast({ title: "Sucesso!", type: "success", description: "A cobrança foi paga." });
        onHideConfirmPagamentoDialog();
      })
      .catch((err) => {
        handleError({ error: err, action: "confirmar pagamento", knownErrors: cobrancaErrors });
        // eslint-disable-next-line no-console
        console.log(err.response);
      })
      .finally(() => setLoadingPagar(false));
  }, [dataPagamento, handleError, id, loadCobranca, showToast, valorRecebido]);

  const cancelarPagamento = useCallback(() => {
    setLoadingCancelar(true);
    api
      .delete(`cobrancas/${id}`)
      .then(() => {
        showToast({ title: "Sucesso!", type: "success", description: "A cobrança foi removida." });
        history.push("/cobranca");
      })
      .catch((err) => {
        handleError({ error: err, action: "remover cobranca", knownErrors: cobrancaErrors });
        // eslint-disable-next-line no-console
        console.log(err.response);
      })
      .finally(() => {
        setLoadingCancelar(false);
      });
  }, [handleError, history, id, showToast]);

  const anistiarPagamento = useCallback(() => {
    setLoadingAnistiar(true);
    api
      .put(`cobrancas/anistiar/${id}`)
      .then(() => {
        showToast({ title: "Sucesso", type: "success", description: "A cobrança foi anistiada" });
        history.push("/cobranca");
      })
      .catch((err) => {
        handleError({ error: err, action: "Anistiar Cobranca", knownErrors: cobrancaErrors });
        console.log(err.response);
      })
      .finally(() => {
        setLoadingAnistiar(false);
      });
  }, [handleError, history, id, showToast]);

  const confirmCancelamento = useCallback(() => {
    confirmDialog({
      message: "Após a remoção o item não poderá ser recuperado.",
      header: "Remover cobrança?",
      icon: "pi pi-question-circle",
      focusOnShow: false,
      rejectLabel: "Manter",
      acceptLabel: "Remover",
      accept: () => cancelarPagamento(),
      reject: () => {},
    });
  }, [cancelarPagamento]);

  const confirmAnistiarPagamento = useCallback(() => {
    confirmDialog({
      message: "Após a anistia o item não poderá ser recuperado.",
      header: "Anistiar cobrança?",
      icon: "pi pi-question-circle",
      focusOnShow: false,
      rejectLabel: "Manter",
      acceptLabel: "Anistiar",
      accept: () => anistiarPagamento(),
      reject: () => {},
    });
  }, [anistiarPagamento]);

  const onReprocessarCobrancaClick = useCallback(() => {
    setLoadingProcessar(true);
    api
      .put(`cobrancas/${id}/reprocessar`)
      .then(() => {
        loadCobranca();
        showToast({
          title: "Sucesso!",
          type: "success",
          description: "A cobrança foi processada.",
        });
      })
      .catch((err) => {
        handleError({ error: err, action: "reprocessar cobranca", knownErrors: cobrancaErrors });
        // eslint-disable-next-line no-console
        console.log(err.response);
        loadCobranca();
      })
      .finally(() => setLoadingProcessar(false));
  }, [handleError, id, loadCobranca, showToast]);

  const confirmPagamentoDialogFooter = () => {
    return (
      <div>
        <Button label="Cancelar" onClick={() => onHideConfirmPagamentoDialog()} className="p-button-text" />
        <Button
          label="Registrar Pagamento"
          className="p-text-center"
          // icon="pi pi-money-bill"
          onClick={efetuarPagamento}
          disabled={loadingPagar || !valorRecebido}
          style={{ width: "180px" }}
          autoFocus
        >
          <Loading isLoading={loadingPagar} size={8} color="#fff" />
        </Button>
      </div>
    );
  };

  const confirmRecurrenceDialogFooter = () => {
    return (
      <div>
        <Button label="Cancelar" onClick={() => hideRecurrenceModal()} className="p-button-text" />
        <Button
          label="Salvar mensalidade"
          className="p-text-center"
          onClick={() => salvarCobranca()}
          disabled={loadingSalvar}
          autoFocus
        >
          <Loading isLoading={loadingSalvar} size={8} color="#fff" />
        </Button>
      </div>
    );
  };

  const getSituacaoErroString = useCallback(() => {
    return situacaoErro?.errors?.map((erro) => erro.description).join("; ") || undefined;
  }, [situacaoErro?.errors]);

  const urlPagamentoBodyTemplate = useMemo(() => {
    return (
      <div className="p-d-flex p-ai-center p-jc-center">
        <InputText
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          id="gerarUrlPagamento"
          value={urlPagamento}
          type="text"
          disabled
        />
        <Button
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          icon="pi pi-copy"
          tooltip="copiar"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            let url = urlPagamento;
            if (url && !/^https?:\/\//i.test(url)) {
              url = `http://${url}`;
            }
            showToast({
              type: "info",
              title: "Ctrl+C",
              description: "Link da cobrança copiado para área de transferência",
            });
            if (url) navigator.clipboard.writeText(url);
          }}
        />
      </div>
    );
  }, [showToast, urlPagamento]);

  const gerarUrlPagamentoBodyTemplate = useMemo(() => {
    return (
      <Button
        icon="pi pi-plus"
        label="Gerar link para cobrança"
        className="input-button-aligned-center"
        disabled={loadingGerarUrl || situacao === "ANISTIADO"}
        onClick={() => {
          setLoadingGerarUrl(true);
          api
            .put(`cobrancas/url-pagamento/${id}`)
            .then(({ data }) => {
              setUrlPagamento(data);
            })
            .catch((err) => {
              handleError({ error: err, action: "gerar link de cobrança", knownErrors: cobrancaErrors });
            })
            .finally(() => {
              setLoadingGerarUrl(false);
            });
        }}
      >
        <div className="p-ml-5">
          <Loading isLoading={loadingGerarUrl} size={8} color="#fff" />
        </div>
      </Button>
    );
  }, [handleError, id, loadingGerarUrl, situacao]);

  const urlReciboBodyTemplate = useMemo(() => {
    let url = urlRecibo;
    if (url && !/^https?:\/\//i.test(url)) {
      url = `http://${url}`;
    }

    return (
      <div className="p-d-flex p-ai-center p-jc-center">
        <InputText
          style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          id="urlRecibo"
          value={url}
          type="text"
          disabled
        />
        <Button
          style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          icon="pi pi-copy"
          tooltip="copiar"
          tooltipOptions={{ position: "top" }}
          onClick={() => {
            showToast({
              type: "info",
              title: "Ctrl+C",
              description: "Link de recibo copiado para área de transferência",
            });
            if (url) navigator.clipboard.writeText(url);
          }}
          disabled={!urlRecibo}
        />
      </div>
    );
  }, [showToast, urlRecibo]);

  return (
    <SimpleEntityListPage showTopBar routeBack="/cobranca" isFormPage loading={loading}>
      <Dialog
        header="Mensalidade recorrente?"
        visible={recurrenceModalVisible}
        modal
        footer={confirmRecurrenceDialogFooter()}
        onHide={() => hideRecurrenceModal()}
        className="confirm-dialog-container"
      >
        <div>
          <div className="p-grid">
            <div className="p-fluid p-col-12">
              <CustomFormField
                labelWidth="7"
                className="p-mt-2"
                icon=""
                htmlForDescription="dataPagamento"
                description="Quantidade de cobranças:"
                inputBody={
                  <InputNumber
                    id="qtdCobranças"
                    value={qtdCobrancaByIrmao}
                    onChange={(e) => {
                      setQtdCobrancaByIrmao(e.value || 1);
                    }}
                    min={1}
                  />
                }
              />
            </div>
          </div>
          <div className="p-mb-2">
            <p>* Será criada 1 cobrança para cada mês a partir da primeira data de vencimento</p>
          </div>
        </div>
      </Dialog>
      <Dialog
        header="Registrar pagamento?"
        visible={displayConfirmPagamentoDialog}
        modal
        // style={{ width: "50vw" }}
        footer={confirmPagamentoDialogFooter()}
        onHide={() => onHideConfirmPagamentoDialog()}
        className="confirm-dialog-container"
      >
        <div className="confirm-dialog-content">
          <div className="confirm-dialog-message">
            <i className="p-confirm-dialog-icon pi pi-question-circle" />
            <span className="p-confirm-dialog-message">
              Após o registro do pagamento o item não poderá ser alterado.
            </span>
          </div>

          <div className="p-grid">
            <div className="p-fluid p-col-12">
              <CustomFormField
                className="p-mt-5 "
                icon="pi pi-calendar"
                htmlForDescription="dataPagamento"
                description="Data de Pagamento"
                inputBody={
                  <Calendar
                    id="dataPagamento"
                    className="p-ml-2"
                    style={{ width: "100%" }}
                    value={dataPagamento}
                    onChange={(e) => {
                      if (!_.isArray(e.value)) {
                        setDataPagamento(e.value);
                      }
                    }}
                    monthNavigator
                    yearNavigator
                    yearRange="1800:2050"
                    monthNavigatorTemplate={monthNavigatorTemplate}
                    yearNavigatorTemplate={yearNavigatorTemplate}
                    locale="pt-br"
                    maxDate={new Date()}
                    showIcon
                  />
                }
              />
              <CustomFormField
                className="p-mt-5"
                icon="pi pi-dollar"
                htmlForDescription="valorRecebido"
                description="Valor Recebido"
                inputBody={
                  <div className="p-inputgroup p-ml-2">
                    <span className="p-inputgroup-addon">R$</span>
                    <InputNumber
                      id="valorRecebido"
                      className=""
                      value={valorRecebido}
                      locale="pt-BR"
                      onChange={(e) => {
                        setValorRecebido(e.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.valorRecebido;
                        setErrors(errorsLocal);
                      }}
                      mode="decimal"
                      minFractionDigits={2}
                    />
                  </div>
                }
                errorMessage={errors.valorRecebido}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <div className="p-grid flex-row-center p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            {id !== "novo" && (
              <CustomFormField
                icon="pi pi-info-circle"
                htmlForDescription="situacao"
                description="Situação"
                inputBody={
                  <div className="p-d-flex p-ai-center">
                    <SituacaoCobranca situacao={situacao} visualizado={visualizado} />
                    {situacao === "PAGO" && dataPagamento && (
                      <span className="p-ml-2">{`Pagamento recebido em ${convertDateToStringBR(
                        dataPagamento.toISOString()
                      )} (${cobrancaInicial.responsavelPagamento})`}</span>
                    )}
                    {situacao === "APROVADO" && dataPagamentoAprovado && (
                      <span className="p-ml-2">{`Pagamento aprovado em ${convertDateToStringBR(
                        dataPagamentoAprovado.toISOString()
                      )}.`}</span>
                    )}

                    {situacao === "ERRO" && situacaoErro && (
                      <span className="p-ml-2 p-d-flex p-flex-wrap">{`Detalhe: ${getSituacaoErroString()}`}</span>
                    )}
                    {(situacao === "ERRO" || situacao === "GERADO") && (
                      <div className="p-ml-auto">
                        {!loadingProcessar && (
                          <Button
                            icon="pi pi-refresh"
                            className=" balandrau-action-button "
                            tooltip="Reprocessar cobrança"
                            onClick={onReprocessarCobrancaClick}
                          />
                        )}
                        <div className="p-mr-2">
                          <Loading isLoading={loadingProcessar} size={8} color="#fff" />
                        </div>
                      </div>
                    )}
                  </div>
                }
              />
            )}
            {id !== "novo" && situacao !== "ESTORNADO" && valorRecebidoExibir && (
              <CustomFormField
                icon="pi pi-dollar"
                htmlForDescription="valorRecebido"
                description="Valor Recebido"
                inputBody={
                  <div className="p-inputgroup">
                    <span className="p-inputgroup-addon">R$</span>
                    <InputNumber
                      id="valor"
                      value={valorRecebidoExibir}
                      locale="pt-BR"
                      mode="decimal"
                      minFractionDigits={2}
                      disabled
                    />
                  </div>
                }
              />
            )}
            {id !== "novo" && situacao !== "ESTORNADO" && urlRecibo && (
              <CustomFormField
                icon="pi pi-link"
                htmlForDescription="urlRecibo"
                description="Link do recibo"
                inputBody={urlReciboBodyTemplate}
              />
            )}
            {id !== "novo" && situacao !== "PAGO" && situacao !== "APROVADO" && situacao !== "ESTORNADO" && (
              <>
                {urlPagamento && (
                  <CustomFormField
                    icon="pi pi-link"
                    htmlForDescription="urlPagamento"
                    description="Link da cobrança"
                    inputBody={urlPagamentoBodyTemplate}
                  />
                )}
                {!urlPagamento && (
                  <CustomFormField
                    icon="pi pi-link"
                    htmlForDescription="urlPagamento"
                    description="Link da cobrança"
                    inputBody={gerarUrlPagamentoBodyTemplate}
                  />
                )}
              </>
            )}
            {id !== "novo" && (situacao === "PAGO" || situacao === "APROVADO" || situacao === "ESTORNADO") && (
              <CustomFormField
                icon="pi pi-wallet"
                htmlForDescription="formaPagamento"
                description="Forma de Pagamento"
                inputBody={
                  <InputText
                    id="formaPagamento"
                    value={formaPagamentoEParcelasString(formaPagamento?.value, parcelas)}
                    disabled
                  />
                }
              />
            )}
          </div>
        </div>
      </div>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} className="modelo-container">
        <TabPanel header="Informações da Cobrança">
          <div className="p-grid flex-row-center p-flex-row p-jc-between">
            <div className="p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomFormField
                  icon="pi pi-dollar"
                  htmlForDescription="valor"
                  description="Valor"
                  info={{
                    id: "valor",
                    tooltip:
                      "Informe o valor original da cobrança sem adicionais ou desconto, os mesmos poderão ser configurados na aba de 'Desconto' e 'Formas de pagamento'. O valor mínimo da cobrança deve ser de R$5,00.",
                    tooltipStyle: { maxWidth: "320px" },
                  }}
                  inputBody={
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">R$</span>
                      <InputNumber
                        id="valor"
                        value={valor || undefined}
                        locale="pt-BR"
                        onChange={(e) => {
                          setValor(e.value);
                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.valor;
                          setErrors(errorsLocal);
                          if (!_.isEqual(cobrancaInicial.valor, e.value)) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }}
                        mode="decimal"
                        minFractionDigits={2}
                        // prefix="R$ "
                        disabled={
                          situacao === "PAGO" ||
                          situacao === "APROVADO" ||
                          situacao === "ESTORNADO" ||
                          situacao === "ANISTIADO"
                        }
                      />
                    </div>
                  }
                  required
                  errorMessage={errors.valor}
                />
                <CustomFormField
                  icon="pi pi-comments"
                  htmlForDescription="descricao"
                  description="Descrição"
                  inputBody={
                    <InputText
                      id="descricao"
                      value={descricao}
                      onChange={(e) => {
                        setDescricao(e.target.value);
                        if (!_.isEqual(cobrancaInicial.descricao, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      disabled={
                        situacao === "PAGO" ||
                        situacao === "APROVADO" ||
                        situacao === "ESTORNADO" ||
                        situacao === "ANISTIADO"
                      }
                    />
                  }
                />
                <CustomFormField
                  // icon="pi pi-calendar"
                  iconMaterial="event"
                  htmlForDescription="dataVencimento"
                  description="Vencimento"
                  info={{
                    id: "dataVencimento",
                    tooltip: "Selecione uma data de vencimento a partir da data de hoje.",
                    tooltipStyle: { maxWidth: "320px" },
                  }}
                  inputBody={
                    <Calendar
                      id="dataVencimento"
                      placeholder="DD/MM/AAAA"
                      value={dataVencimento}
                      onChange={(e) => {
                        if (e.target && e.target.value) {
                          setDataFormatada(formatDateToStringForSend(e.target.value.toLocaleString("en-us")) || "");
                          setDataVencimento(e.target.value);
                          if (
                            !_.isEqual(
                              cobrancaInicial.dtVencimento,
                              formatDateToStringForSend(e.target.value.toLocaleString("en-us"))
                            )
                          ) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.dataVencimento;
                        setErrors(errorsLocal);
                      }}
                      monthNavigator
                      yearNavigator
                      yearRange="1800:2050"
                      monthNavigatorTemplate={monthNavigatorTemplate}
                      yearNavigatorTemplate={yearNavigatorTemplate}
                      locale="pt-br"
                      // minDate={new Date()}
                      disabled={
                        situacao === "PAGO" ||
                        situacao === "APROVADO" ||
                        situacao === "ESTORNADO" ||
                        situacao === "ANISTIADO"
                      }
                      showIcon
                    />
                  }
                  required
                  errorMessage={errors.dataVencimento}
                />
                <CustomFormField
                  icon="ba ba-tipo"
                  htmlForDescription="tipo"
                  description="Tipo"
                  inputBody={
                    <Dropdown
                      placeholder="Selecione"
                      options={tipoOptions}
                      value={tipo}
                      onChange={(e) => {
                        setIrmao([]);

                        if (e.value.tipo === "GRANDE_TRONCO") {
                          loadDropdownIrmaosRemidos();
                        } else if (e.value.tipo === "COTA") {
                          loadDropdownIrmaosCota();
                        } else {
                          loadDropdown();
                        }

                        if (e.value.tipo === "MENSALIDADE" || e.value.tipo === "MENSALIDADE_GLOMAM") {
                          setQuebraEmMensalidades(false);
                        }

                        setTipo(e.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.tipo;
                        setErrors(errorsLocal);
                        if (!_.isEqual(cobrancaInicial.tipo, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      disabled={
                        situacao === "PAGO" ||
                        situacao === "APROVADO" ||
                        situacao === "ESTORNADO" ||
                        situacao === "ANISTIADO"
                      }
                    />
                  }
                  required
                  errorMessage={errors.tipo}
                />
                <CustomFormField
                  icon="ba ba-usuario"
                  htmlForDescription="irmao"
                  description="Irmão(s)"
                  inputBody={
                    <MultiSelect
                      placeholder="Selecione"
                      disabled={
                        id !== "novo" ||
                        situacao === "PAGO" ||
                        situacao === "APROVADO" ||
                        situacao === "ESTORNADO" ||
                        situacao === "ANISTIADO" ||
                        loadingDropdown
                      }
                      showClear
                      dataKey="uuid"
                      optionLabel="nome"
                      options={irmaoOptions}
                      value={irmao}
                      onChange={(e) => {
                        setIrmao(e.target.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.irmao;
                        setErrors(errorsLocal);
                        if (!_.isEqual(cobrancaInicial.irmao, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      maxSelectedLabels={3}
                      selectedItemsLabel="{0} irmãos selecionados"
                      emptyFilterMessage="Nenhum irmão encontrado"
                      filter
                      filterBy="nome"
                    />
                  }
                  required
                  errorMessage={errors.irmao}
                />
                <CustomFormField
                  // icon="pi pi-dollar"
                  iconMaterial="event_repeat"
                  htmlForDescription="quebraEmMensalidades"
                  description="Pode transformar em cobranças mensais?"
                  info={{
                    id: "quebraEmMensalidades",
                    tooltip:
                      "Ao selecionar a opção 'sim' o irmão cobrado poderá transformar esta cobrança em N cobranças menores (mensalidades / parcelas). As cobranças mensais serão criadas a partir da data de vencimento desta cobrança. O desconto não será repassado às cobranças mensais, uma vez que este é aplicado apenas para os pagamentos à vista. Além disso, não será possível parcelar uma cobrança mensal no cartão de crédito.",
                    tooltipStyle: { maxWidth: "320px" },
                  }}
                  inputBody={
                    <div className="p-d-flex p-ai-center" style={{ paddingTop: 2, paddingBottom: 2 }}>
                      <RadioButton
                        value
                        inputId="sim-usar-nome"
                        onChange={(e) => {
                          setQuebraEmMensalidades(e.value);
                          setIsDirty(true);
                        }}
                        checked={quebraEmMensalidades}
                        disabled={
                          situacao === "PAGO" ||
                          situacao === "APROVADO" ||
                          situacao === "ESTORNADO" ||
                          situacao === "ANISTIADO" ||
                          tipo.tipo === "MENSALIDADE" ||
                          tipo.tipo === "MENSALIDADE_GLOMAM"
                        }
                      />
                      <label htmlFor="sim-usar-nome" className="p-ml-2">
                        Sim
                      </label>
                      <RadioButton
                        value={false}
                        inputId="nao-usar-nome"
                        onChange={(e) => {
                          setQuebraEmMensalidades(e.value);
                          setIsDirty(true);
                        }}
                        checked={!quebraEmMensalidades}
                        className="p-ml-4"
                        disabled={
                          situacao === "PAGO" ||
                          situacao === "APROVADO" ||
                          situacao === "ESTORNADO" ||
                          tipo.tipo === "MENSALIDADE" ||
                          tipo.tipo === "MENSALIDADE_GLOMAM" ||
                          situacao === "ANISTIADO"
                        }
                      />
                      <label htmlFor="nao-usar-nome" className="p-ml-2">
                        Não
                      </label>
                    </div>
                  }
                  errorMessage={errors.quebraEmMensalidades}
                />

                {tipo.tipo !== "MENSALIDADE" && tipo.tipo !== "MENSALIDADE_GLOMAM" && quebraEmMensalidades && (
                  <CustomFormField
                    // icon="pi pi-dollar"
                    iconMaterial="price_change"
                    htmlForDescription="qtdMensalidades"
                    description="Quantidade de cobranças mensais"
                    info={{
                      id: "qtdMensalidades",
                      tooltip:
                        "Informe quantas cobranças mensais deverão ser criadas para esta cobrança, caso o irmão deseje. Note que o valor mínimo de uma cobrança mensal deve ser de R$5,00.",
                      tooltipStyle: { maxWidth: "320px" },
                    }}
                    inputBody={
                      <div className="p-inputgroup">
                        <Dropdown
                          placeholder="Selecione"
                          options={qtdsMensalidades}
                          value={qtdMensalidades}
                          onChange={(e) => {
                            setQtdMensalidades(e.value);
                            const errorsLocal = _.cloneDeep(errors);
                            delete errorsLocal.qtdMensalidades;
                            setErrors(errorsLocal);
                            if (!_.isEqual(cobrancaInicial.qtdMensalidades, e.target.value)) {
                              setIsDirty(true);
                            } else {
                              setIsDirty(false);
                            }
                          }}
                          disabled={
                            situacao === "PAGO" ||
                            situacao === "APROVADO" ||
                            situacao === "ESTORNADO" ||
                            situacao === "ANISTIADO"
                          }
                        />
                      </div>
                    }
                    errorMessage={errors.qtdMensalidades}
                  />
                )}
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Desconto">
          <div className="p-grid flex-row-center p-flex-row p-jc-between">
            <div className="p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomFormField
                  // icon="pi pi-dollar"
                  iconMaterial="local_offer"
                  htmlForDescription="tipoDesconto"
                  description="Desconto"
                  inputBody={
                    <Dropdown
                      placeholder="Selecione"
                      options={[tiposDescontoMap.PERCENTUAL, tiposDescontoMap.VALOR_FIXO]}
                      value={tipoDesconto}
                      onChange={(e) => {
                        setTipoDesconto(e.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.tipoDesconto;
                        setErrors(errorsLocal);
                        if (!_.isEqual(cobrancaInicial.tipoDesconto, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      disabled={
                        situacao === "PAGO" ||
                        situacao === "APROVADO" ||
                        situacao === "ESTORNADO" ||
                        situacao === "ANISTIADO"
                      }
                    />
                  }
                  required
                  errorMessage={errors.tipoDesconto}
                />
                {tipoDesconto.tipo === "PERCENTUAL" && (
                  <CustomFormField
                    // icon="pi pi-dollar"
                    iconMaterial="percent"
                    htmlForDescription="percentualDesconto"
                    description="Valor percentual do desconto"
                    inputBody={
                      <div className="p-inputgroup">
                        <span className="p-inputgroup-addon">%</span>
                        <InputNumber
                          id="percentualDesconto"
                          value={percentualDesconto}
                          // suffix="%"
                          min={0.0}
                          max={100.0}
                          mode="decimal"
                          maxFractionDigits={1}
                          locale="pt-BR"
                          onChange={(e) => {
                            if (e.value !== null) {
                              setPercentualDesconto(e.value);
                            } else {
                              setPercentualDesconto(0);
                            }
                            if (!_.isEqual(cobrancaInicial.percentualDesconto, e.value)) {
                              setIsDirty(true);
                            } else {
                              setIsDirty(false);
                            }
                          }}
                          onValueChange={(e) => {
                            if (e.value !== null) {
                              setPercentualDesconto(e.value);
                            } else {
                              setPercentualDesconto(0);
                            }

                            if (!_.isEqual(cobrancaInicial.percentualDesconto, e.target.value)) {
                              setIsDirty(true);
                            } else {
                              setIsDirty(false);
                            }
                          }}
                          disabled={
                            situacao === "PAGO" ||
                            situacao === "APROVADO" ||
                            situacao === "ESTORNADO" ||
                            situacao === "ANISTIADO"
                          }
                        />
                      </div>
                    }
                    required
                    errorMessage={errors.percentualDesconto}
                  />
                )}
                <CustomFormField
                  // icon="pi pi-dollar"
                  iconMaterial="money_off"
                  htmlForDescription="valorDesconto"
                  description="Valor fixo do desconto"
                  info={{
                    id: "valorDesconto",
                    tooltip:
                      "O valor do desconto é calculado e aplicado sobre o valor original da cobrança. A diferença entre o valor original da cobrança e o valor do desconto deve ser maior que R$5,00",
                    tooltipStyle: { maxWidth: "320px" },
                  }}
                  inputBody={
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">R$</span>
                      <InputNumber
                        id="valorDesconto"
                        value={valorDesconto}
                        locale="pt-BR"
                        onChange={(e) => {
                          if (e.value) {
                            setValorDesconto(e.value);
                          }
                          if (!_.isEqual(cobrancaInicial.valorDesconto, e.value)) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }}
                        mode="decimal"
                        minFractionDigits={2}
                        // prefix="R$ "
                        disabled={
                          tipoDesconto.tipo === "PERCENTUAL" ||
                          situacao === "PAGO" ||
                          situacao === "APROVADO" ||
                          situacao === "ESTORNADO" ||
                          situacao === "ANISTIADO"
                        }
                      />
                    </div>
                  }
                  required
                  errorMessage={errors.valorDesconto}
                />
                <CustomFormField
                  // icon="pi pi-dollar"
                  iconMaterial="event"
                  htmlForDescription="prazoDesconto"
                  description="Prazo máximo do desconto"
                  inputBody={
                    <Dropdown
                      placeholder="Selecione"
                      options={prazosDesconto}
                      value={prazoDesconto}
                      onChange={(e) => {
                        setPrazoDesconto(e.value);
                        const errorsLocal = _.cloneDeep(errors);
                        delete errorsLocal.prazoDesconto;
                        setErrors(errorsLocal);
                        if (!_.isEqual(cobrancaInicial.prazoDesconto, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      disabled={
                        situacao === "PAGO" ||
                        situacao === "APROVADO" ||
                        situacao === "ESTORNADO" ||
                        situacao === "ANISTIADO"
                      }
                    />
                  }
                  required
                  errorMessage={errors.prazoDesconto}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Formas de Pagamento">
          <div className="p-grid flex-row-center p-flex-row p-jc-between">
            <div className="p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <CustomFormField
                  icon="pi pi-wallet"
                  htmlForDescription="formaPagamentoAceita"
                  description="Forma de Pagamento aceita"
                  info={{
                    id: "formaPagamentoAceita",
                    tooltip: "Este campo controla quais formas de pagamento serão exibidas para o irmão.",
                    tooltipStyle: { maxWidth: "320px" },
                  }}
                  inputBody={
                    <Dropdown
                      placeholder="Selecione"
                      options={[formaPagamentoMap.UNDEFINED, formaPagamentoMap.BOLETO, formaPagamentoMap.CREDIT_CARD]}
                      optionLabel="labelAlt"
                      optionValue="value"
                      dataKey="value"
                      value={formaPagamentoAceita.value}
                      onChange={(e) => {
                        if ((e.target.value as FormaPagamento) && !!formaPagamentoAceita) {
                          const selected: FormaPagamento = e.target.value;
                          setFormaPagamentoAceita(formaPagamentoMap[selected]);
                        }
                        if (!_.isEqual(cobrancaInicial.formaPagamentoAceita, e.target.value)) {
                          setIsDirty(true);
                        } else {
                          setIsDirty(false);
                        }
                      }}
                      disabled={
                        situacao === "PAGO" ||
                        situacao === "APROVADO" ||
                        situacao === "ESTORNADO" ||
                        situacao === "ANISTIADO"
                      }
                    />
                  }
                  required
                  errorMessage={errors.formaPagamentoAceita}
                />

                <CustomFormField
                  // icon="pi pi-dollar"
                  iconMaterial="monitoring"
                  htmlForDescription="valorExtraPixBoleto"
                  description="Valor extra para Pix ou boleto"
                  info={{
                    id: "valorExtraPixBoleto",
                    tooltip:
                      "O valor extra para os pagamentos com Pix ou boleto será adicionado após a aplicação do desconto (caso exista). Além disso, ao transformar em cobranças mensais o valor extra também será repassado proporcionalmente às mensalidades / parcelas criadas.",
                    tooltipStyle: { maxWidth: "320px" },
                  }}
                  inputBody={
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">R$</span>
                      <InputNumber
                        id="valorExtraPixBoleto"
                        value={valorExtraPixBoleto}
                        locale="pt-BR"
                        onChange={(e) => {
                          if (e.value) {
                            setValorExtraPixBoleto(e.value);
                          }

                          if (!_.isEqual(cobrancaInicial.valorExtraPixBoleto, e.value)) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }}
                        mode="decimal"
                        minFractionDigits={2}
                        // prefix="R$ "
                        disabled={
                          (formaPagamentoAceita.value !== "BOLETO" && formaPagamentoAceita.value !== "UNDEFINED") ||
                          situacao === "PAGO" ||
                          situacao === "APROVADO" ||
                          situacao === "ESTORNADO" ||
                          situacao === "ANISTIADO"
                        }
                      />
                    </div>
                  }
                  errorMessage={errors.valorExtraPixBoleto}
                />

                <CustomFormField
                  // icon="pi pi-dollar"
                  iconMaterial="monitoring"
                  htmlForDescription="valorExtraCartaoCredito"
                  description="Valor extra para cartão de crédito"
                  info={{
                    id: "valorExtraCartaoCredito",
                    tooltip:
                      "O valor extra para os pagamentos com cartão de crédito será adicionado após a aplicação do desconto (caso exista). Este valor adicional é independente do parcelamento ou não da cobrança no cartão. Da mesma forma, ao transformar em cobranças mensais o valor extra também será repassado proporcionalmente às mensalidades / parcelas criadas.",
                    tooltipStyle: { maxWidth: "320px" },
                  }}
                  inputBody={
                    <div className="p-inputgroup">
                      <span className="p-inputgroup-addon">R$</span>
                      <InputNumber
                        id="valorExtraCartaoCredito"
                        value={valorExtraCartaoCredito}
                        locale="pt-BR"
                        onChange={(e) => {
                          if (e.value) {
                            setValorExtraCartaoCredito(e.value);
                          }

                          if (!_.isEqual(cobrancaInicial.valorExtraCartaoCredito, e.value)) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }}
                        mode="decimal"
                        minFractionDigits={2}
                        // prefix="R$ "
                        disabled={
                          (formaPagamentoAceita.value !== "CREDIT_CARD" &&
                            formaPagamentoAceita.value !== "UNDEFINED") ||
                          situacao === "PAGO" ||
                          situacao === "APROVADO" ||
                          situacao === "ESTORNADO" ||
                          situacao === "ANISTIADO"
                        }
                      />
                    </div>
                  }
                  errorMessage={errors.valorExtraPixBoleto}
                />

                <CustomFormField
                  // icon="pi pi-dollar"
                  iconMaterial="price_change"
                  htmlForDescription="maximoParcelas"
                  description="Parcelamento no cartão"
                  required
                  inputBody={
                    <div className="p-inputgroup">
                      <Dropdown
                        placeholder="Selecione"
                        options={maximosParcelas}
                        value={maximoParcelas}
                        onChange={(e) => {
                          setMaximoParcelas(e.value);
                          const errorsLocal = _.cloneDeep(errors);
                          delete errorsLocal.maximoParcelas;
                          setErrors(errorsLocal);
                          if (!_.isEqual(cobrancaInicial.maximoParcelas, e.target.value)) {
                            setIsDirty(true);
                          } else {
                            setIsDirty(false);
                          }
                        }}
                        disabled={
                          (formaPagamentoAceita.value !== "CREDIT_CARD" &&
                            formaPagamentoAceita.value !== "UNDEFINED") ||
                          situacao === "PAGO" ||
                          situacao === "APROVADO" ||
                          situacao === "ESTORNADO" ||
                          situacao === "ANISTIADO"
                        }
                      />
                    </div>
                  }
                  errorMessage={errors.maximoParcelas}
                />

                {/* <div style={{ marginBottom: 300 }} /> */}
              </div>
            </div>
          </div>
        </TabPanel>
      </TabView>

      {id !== "novo" && (
        <>
          <FormHeader headerMessage="Operações" />
          <div className="p-grid flex-row-center p-flex-row p-jc-between">
            <div className="p-col-7 p-grid">
              <div className="p-fluid p-col-12">
                <div className="p-d-flex p-jc-between">
                  <Button
                    label="Registrar Pagamento"
                    icon="pi pi-money-bill"
                    className="operation-button background-verde-situacao input-button-aligned-center p-mr-2"
                    onClick={onShowConfirmPagamentoDialog}
                    disabled={isDirty || situacao === "PAGO" || situacao === "APROVADO" || situacao === "ANISTIADO"}
                  />

                  <Button
                    label="Anistiar Pagamento"
                    icon="fa-regular fa-handshake"
                    className="operation-button background-azul-situacao input-button-aligned-center p-mr-2"
                    onClick={confirmAnistiarPagamento}
                    disabled={isDirty || situacao === "PAGO" || situacao === "APROVADO" || situacao === "ANISTIADO"}
                  />

                  <Button
                    className="operation-button background-vermelho-situacao input-button-aligned-center"
                    label="Remover Cobrança"
                    icon="pi pi-times-circle"
                    onClick={confirmCancelamento}
                    disabled={
                      loadingCancelar ||
                      isDirty ||
                      situacao === "PAGO" ||
                      situacao === "APROVADO" ||
                      situacao === "ANISTIADO"
                    }
                  >
                    <Loading isLoading={loadingCancelar} size={8} color="#fff" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {isDirty && (
        <FloatingSave
          resetCommand={() => redefinirCobranca()}
          disabled={loadingSalvar || !validacao()}
          saveCommand={() => {
            if ((tipo.tipo === "MENSALIDADE" || tipo.tipo === "MENSALIDADE_GLOMAM") && id === "novo")
              showRecurrenceModal();
            else salvarCobranca();
          }}
          loadingOnSave={loadingSalvar}
        />
      )}
    </SimpleEntityListPage>
  );
};

export default CobrancaFormPage;
