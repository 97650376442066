import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { confirmDialog } from "primereact/confirmdialog";
import { InputText } from "primereact/inputtext";
import _ from "lodash";
import { MobileVersionDTO, Params } from "../types";
import SimpleEntityPage from "../../../components/SimpleEntityPage";
import api from "../../../services/api";
import { useToast } from "../../../hooks/toast";
import { useError } from "../../../hooks/error";
import { mobileVersionErrors } from "../../../errors/mobile-version";
import CustomFormField from "../../../components/CustomFormField";
import FloatingSave from "../../../components/FloatingSave";

const MobileVersionForm: React.FC = () => {
  const { id } = useParams<Params>();
  const history = useHistory();
  const { showToast } = useToast();
  const { handleError } = useError();

  const [loading, setLoading] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);
  const [version, setVersion] = useState<string>("");
  const [versionInicial, setVersionInicial] = useState({} as MobileVersionDTO);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const [errors, setErrors] = useState<{ [errorKey: string]: string }>({});

  const excluirVersao = useCallback(() => {
    api
      .delete(`mobile-version/${id}`)
      .then(() => {
        showToast({ title: "Sucesso!", type: "success", description: "A versão foi excluída" });
        history.push("/mobile-version");
      })
      .catch((err) => {
        handleError({ error: err, action: "excluir versão", knownErrors: mobileVersionErrors });
      });
  }, [handleError, history, id, showToast]);

  const confirmExclusion = useCallback(() => {
    confirmDialog({
      message: "Após a exclusão o item não poderá ser recuperado.",
      header: "Excluir versão?",
      icon: "pi pi-question-circle",
      focusOnShow: false,
      rejectLabel: "Manter",
      acceptLabel: "Excluir",
      accept: () => excluirVersao(),
      reject: () => {},
    });
  }, [excluirVersao]);

  const exclusionCommand = useCallback(() => {
    if (id !== "novo" && id !== undefined) return confirmExclusion();
    return () => {};
  }, [confirmExclusion, id]);

  const loadInfo = useCallback(() => {
    setLoading(true);
    api
      .get<MobileVersionDTO>(`mobile-version/${id}`)
      .then(({ data }) => {
        setVersion(data.version);
        setVersionInicial(data);
      })
      .catch((err) => {
        handleError({ action: "carregar versão", error: err, knownErrors: mobileVersionErrors });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [handleError, id]);

  const redefineVersion = useCallback(() => {
    setIsDirty(false);

    if (id === "novo" || id === undefined) {
      setVersion("");
    } else {
      loadInfo();
    }
  }, [id, loadInfo]);

  const validateFields = useCallback(() => {
    const errorsLocal: { [campo: string]: string } = {};

    const versionSplitted = version.split(".");

    if (versionSplitted.length !== 3) errorsLocal.version = "O campo precisa está separado por 3 pontos. Ex.: x.y.z";

    if (
      Number.isNaN(Number(versionSplitted[0])) ||
      Number.isNaN(Number(versionSplitted[1])) ||
      Number.isNaN(Number(versionSplitted[2]))
    )
      errorsLocal.version = "A versão deve ser composta apenas por números e .";

    if (_.isEmpty(version)) {
      errorsLocal.version = "Este é um campo obrigatório";
    }

    setErrors(errorsLocal);
    return _.isEmpty(errorsLocal);
  }, [version]);

  const saveVersion = useCallback(() => {
    const valid = validateFields();

    if (valid) {
      if (id === "novo" || id === undefined) {
        setLoadingSalvar(true);
        api
          .post("mobile-version", { version })
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "Uma nova versão foi cadastrada." });
            history.push("/mobile-version");
          })
          .catch((err) => {
            handleError({ error: err, action: "criar versão", knownErrors: mobileVersionErrors });
          })
          .finally(() => setLoadingSalvar(false));
      } else {
        setLoadingSalvar(true);
        api
          .put(`mobile-version/${id}`, {
            version,
          })
          .then(() => {
            showToast({ title: "Sucesso!", type: "success", description: "A versão foi editada e salva." });
            history.push("/mobile-version");
          })
          .catch((err) => {
            handleError({ error: err, action: "atualizar versão", knownErrors: mobileVersionErrors });
          })
          .finally(() => setLoadingSalvar(false));
      }
    }
  }, [handleError, history, id, showToast, validateFields, version]);

  const validate = useCallback(() => {
    return !_.isEmpty(version.trim()) && !_.isEqual(versionInicial.version, version);
  }, [version, versionInicial.version]);

  useEffect(() => {
    if (id !== "novo" && id !== undefined) loadInfo();
  }, [id, loadInfo]);

  return (
    <SimpleEntityPage
      showTopBar
      routeBack="/mobile-version"
      excludeCommand={exclusionCommand}
      excludeLabel="Excluir versão"
      isFormPage
      loading={loading}
    >
      <div className="p-grid p-flex-row p-jc-between">
        <div className="p-col-7 p-grid">
          <div className="p-fluid p-col-12">
            <CustomFormField
              icon="fa fa-cogs"
              htmlForDescription="version"
              description="Num. da versão"
              inputBody={
                <InputText
                  id="version"
                  value={version}
                  type="text"
                  placeholder="Ex.: 2.0.20"
                  onChange={(e) => {
                    setVersion(e.target.value);
                    const errorsLocal = _.cloneDeep(errors);
                    delete errorsLocal.nome;
                    setErrors(errorsLocal);
                    if (!_.isEqual(versionInicial.version, e.target.value)) {
                      setIsDirty(true);
                    } else {
                      setIsDirty(false);
                    }
                  }}
                  maxLength={10}
                />
              }
              required
              errorMessage={errors.version}
            />
          </div>
        </div>
      </div>
      <div>
        {isDirty ? (
          <FloatingSave
            resetCommand={redefineVersion}
            disabled={!validate()}
            saveCommand={saveVersion}
            loadingOnSave={loadingSalvar}
          />
        ) : null}
      </div>
    </SimpleEntityPage>
  );
};

export default MobileVersionForm;
